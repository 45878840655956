import React from 'react';
import ServiceHero from './Service/ServiceHero';
import ServiceList from './Service/ServiceList';
import ServiceSection from './Service/ServiceSection';
import TestimonialsSection from './Home/Testimonialsection';
import ContactUs from './Service/ContactSection';
import FAQSection from './Service/FaqSection';

const Services = () => {
  return (
    <>
      <ServiceHero />
      <ServiceSection />
      <FAQSection />
    </>
  );
};

export default Services;
