import React from 'react';
import { Link } from 'react-router-dom';

const HomePageHero = () => {
  return (
    <div className="relative bg-gradient-to-r from-primary-50 via-white to-primary-100 text-gray-900 overflow-hidden">
      <div className="container mx-auto px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none md:leading-[1.14em]">
              Transform Your Hiring Process with <span className="inline-block font-bold text-primary-600"> HR.AI</span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Revolutionize your recruitment with our AI-driven platform. Automate initial screenings, conduct smart interviews, and focus on the best candidates for your company.
              </p>
            </div>
            <div>
              <Link
                to="/signup"
                aria-label=""
                className="group inline-flex items-center justify-center rounded-full py-2 px-6 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-primary-600 to-primary-500 text-white hover:from-primary-700 hover:to-primary-600 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600"
              >
                Get Started Today
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"></path>
                </svg>
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src="https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                alt="AI Interview"
              />
              <img
                className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src="https://images.pexels.com/photos/3205567/pexels-photo-3205567.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Data Analysis"
              />
            </div>
            <div className="px-3">
              <img
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                src="https://images.pexels.com/photos/8453804/pexels-photo-8453804.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Recruitment Process"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageHero;
