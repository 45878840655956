import React, { useState } from 'react';
import { FaFileInvoiceDollar, FaChartPie, FaRegChartBar, FaClipboardList } from 'react-icons/fa';

const features = [
  {
    id: 'invoice-extraction',
    title: 'AI-Powered Resume Screening',
    description: "Automate the initial screening of resumes with our advanced AI technology. Quickly identify the most qualified candidates based on your specific criteria.",
    icon: <FaFileInvoiceDollar size={50} className="text-primary-500" />,
    imageSrc: './images/hrai-resumeevaluation.png',
  },
  {
    id: 'expense-tracking',
    title: 'Automated Interview Evaluation',
    description: "Leverage AI to evaluate candidate responses in the first round of interviews. Save time and ensure consistency in your interview process.",
    icon: <FaClipboardList size={50} className="text-green-500" />,
    imageSrc: './images/hrai-liveinterview.png',
  },
  { 
    id: 'vat-handling',
    title: 'Comprehensive Analytics',
    description: "Gain insights into your hiring process with detailed analytics. Track key metrics and make data-driven decisions to improve your recruitment strategy.",
    icon: <FaChartPie size={50} className="text-yellow-500" />,
    imageSrc: './images/hrai-dashboard.png',
  },
];

const FeaturesSection = () => {
  const [selectedTab, setSelectedTab] = useState(features[0].id);

  const selectedFeature = features.find(feature => feature.id === selectedTab);

  return (
    <section className="py-20 bg-gradient-to-r from-primary-50 to-gray-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-5xl font-bold text-gray-800">Explore Our Key Features</h2>
          <p className="mt-4 text-lg text-gray-600">Transform your hiring process with our innovative AI-driven solutions.</p>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col lg:w-1/3 space-y-4">
            {features.map(feature => (
              <div
                key={feature.id}
                className={`p-6 rounded-lg cursor-pointer transition-colors duration-300 ${selectedTab === feature.id ? 'bg-primary-500 text-white' : 'bg-white text-gray-800 hover:bg-primary-100'}`}
                onClick={() => setSelectedTab(feature.id)}
              >
                <div className="flex items-center space-x-4">
                  {feature.icon}
                  <div>
                    <h3 className="text-xl font-semibold">{feature.title}</h3>
                    <p className={`${selectedTab === feature.id ? 'text-gray-200' : 'text-gray-500' }`}>{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="lg:w-2/3 mt-10 lg:mt-0 lg:pl-12">
            {selectedFeature && (
              <div className="relative rounded-lg py-24 bg-gray-50 shadow-lg">
                <div className=" overflow-hidden">
                  <img src={ selectedFeature.imageSrc} alt={selectedFeature.title} className="w-full" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
