import React from 'react';
import ContactUs from './Contact/ContactUs';
import ContactHero from './Contact/ContactHero';
import ContactMap from './Contact/ContactMap';
import ContactFAQ from './Contact/FaqContact';

const Contact = () => {
  return (
    <>
      <ContactHero />
      <ContactUs />
      {/* <ContactMap /> */}
      <ContactFAQ />
    </>
  );
};

export default Contact;
