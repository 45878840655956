import React, { useState } from 'react';
import { FaPlus, FaMinus } from "react-icons/fa6";

const faqs = [
  {
    question: "What is the Invoice Extractor and How Does it Work?",
    answer: "The Invoice Extractor is a sophisticated tool designed to automate the extraction and processing of invoice data using advanced generative AI technology. It scans and digitizes invoices, extracting key data points and integrating them directly into your accounting software, reducing manual work and errors."
  },
  {
    question: "What are the Benefits and Security of Invoice Extractor?",
    answer: "The benefits of using the Invoice Extractor include increased efficiency, reduced manual data entry, fewer errors, faster processing times, and seamless integration with various accounting systems. It is designed with security in mind, ensuring that your data is encrypted and handled in compliance with industry standards."
  },
  {
    question: "How Versatile is the Invoice Extractor?",
    answer: "The Invoice Extractor is capable of processing a wide variety of invoice formats and layouts, making it versatile and adaptable to different business needs. Getting started is simple—contact our sales team for a demo and to discuss your specific requirements. We'll guide you through the setup and integration process."
  },
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-gradient-to-r from-primary-50 to-gray-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <p className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Transforming the Way You Process Invoices
          </p>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            We are committed to providing innovative and efficient solutions to streamline your invoicing process, saving you time and resources.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-8 items-start">
          <div className="lg:flex-1 flex justify-center mb-8 lg:mb-0">
            <img src="images/invoice-extraction.jpeg" alt="FAQ Illustration" className="w-full max-w-md rounded-lg shadow-lg" />
          </div>
          <div className="lg:flex-1 space-y-8">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md px-4 py-4 hover:shadow-lg transition-shadow duration-300">
                <button
                  onClick={() => toggleFAQ(index)}
                  className="flex justify-between items-center w-full text-left text-lg font-medium text-gray-900 focus:outline-none"
                >
                  <span>{faq.question}</span>
                  {activeIndex === index ? <FaMinus className="text-primary-600" /> : <FaPlus className="text-primary-600" />}
                </button>
                <div className={`overflow-hidden transition-max-height duration-300 ease-in-out ${activeIndex === index ? 'max-h-96' : 'max-h-0'}`}>
                  <p className="mt-4 text-gray-700">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
