import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Frontend/page/Home';
import About from '../Frontend/page/About';
import Services from '../Frontend/page/Service';
import Contact from '../Frontend/page/Contact';
import SigninPage from '../Frontend/page/Auth/Signin'; 
import ForgetPassword from '../Frontend/page/Auth/ForgetPassword'; 
import EmailVerification from '../Frontend/page/Auth/EmailVerification'; 
import PrivacyPolicy from '../Frontend/page/PrivacyPolicy';
import TermsAndConditions from '../Frontend/page/TermsAndConditions ';
import SignupPage from '../Frontend/page/Auth/Signup';
import CreateNewPassword from '../Frontend/page/Auth/CreateNewPassword';
import DashboardHome from '../Dashboard/DashboardHome';
import FrontendLayout from '../Layouts/FrontendLayout';
import DashboardLayout from '../Layouts/DashboardLayout';
import Team from '../Dashboard/Page/Team';
import AddTeamMember from '../Dashboard/Page/Team/AddTeamMember';
import Profile from '../Dashboard/Page/Profile';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import EmailVerified from '../Frontend/page/Auth/EmailVerified';
import JobView from '../Dashboard/Page/JobView';
import AddJob from '../Dashboard/Page/Jobs/AddJob';
import LiveQnaList from '../Dashboard/Page/LiveQna/LiveQnaList';
import CreateLiveQnaInterview from '../Dashboard/Page/LiveQna/CreateLiveQna';
import LiveQue from '../Dashboard/Page/LiveQna/LiveQue';
import LiveQnaResult from '../Dashboard/Page/LiveQna/LiveQnaResult';
import AudioInterview from '../Dashboard/Page/LiveQna/InterviewType/AudioInterview';
import TextInterview from '../Dashboard/Page/LiveQna/InterviewType/TextInterview';
import InterviewSelection from '../Dashboard/Page/LiveQna/InterviewSelection';
import Evaluation from '../Dashboard/Page/ResumeEvaluate.js/Evaluate';
import EvaluatedList from '../Dashboard/Page/ResumeEvaluate.js/EvaluatedList';
import EvaluatedResult from '../Dashboard/Page/ResumeEvaluate.js/EvaluatedResult';
import Unauthorized from '../Unauthorized';
import Candidates from '../Dashboard/Page/Candidate/Candidates';
import Jobs from '../Frontend/page/Job/jobs';
import JobDetails from '../Frontend/page/Job/JobDetails';
import ResumeEvaluation from '../Frontend/page/Job/ResumeEvaluation';
import Interview from '../Frontend/page/LiveQna/Interview';
import CandidateDashboard from '../Frontend/page/Candidate/CandidateDashboard';
import InterviewResult from '../Frontend/page/LiveQna/InterviewResult';
import ResumeEvResult from '../Frontend/page/Job/ResumeEvResult';

const RouteList = () => {
  return (
    <Routes>
      <Route element={<FrontendLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/service" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/term-and-condition" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/job-details" element={<JobDetails />} />
        <Route path="/candidate-dashboard" element={<ProtectedRoute element={<CandidateDashboard />} requiredPermission="view_interview"/>} />
        <Route path="/resume-evaluation" element={<ProtectedRoute element={<ResumeEvaluation />} requiredPermission="view_interview"/>} />
        <Route path="/interview" element={<ProtectedRoute element={<Interview />} requiredPermission="view_interview"/>} />
        <Route path="/interview-result" element={<ProtectedRoute element={<InterviewResult />} requiredPermission="view_interview"/>} />
        <Route path="/resume-evaluation-result" element={<ProtectedRoute element={<ResumeEvResult />} requiredPermission="view_interview"/>} />
        </Route>
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<ProtectedRoute element={<DashboardHome />} requiredPermission="view_dashboard" />} />
        <Route path="/jobList" element={<ProtectedRoute element={<JobView />} requiredPermission="view_dashboard" />} />
        <Route path="/add-job" element={<ProtectedRoute element={<AddJob />} requiredPermission="view_dashboard" />} />
        <Route path="/edit-job" element={<ProtectedRoute element={<AddJob />} requiredPermission="view_dashboard" />} />
        <Route path="/candidate-resume-evaluation" element={<ProtectedRoute element={<Evaluation />} requiredPermission="view_dashboard" />} />
        <Route path="/evaluated-resumes" element={<ProtectedRoute element={<EvaluatedList />} requiredPermission="view_dashboard" />} />
        <Route path="/evaluated-result" element={<ProtectedRoute element={<EvaluatedResult />} requiredPermission="view_dashboard" />} />
        <Route path="/live-qna" element={<ProtectedRoute element={<LiveQnaList />} requiredPermission="view_dashboard" />} />
        <Route path="/create-live-qna" element={<ProtectedRoute element={<CreateLiveQnaInterview />} requiredPermission="view_dashboard" />} />
        <Route path="/interview-selection" element={<ProtectedRoute element={<InterviewSelection />} requiredPermission="view_dashboard" />} />
        <Route path="/live-interview" element={<ProtectedRoute element={<LiveQue />} requiredPermission="view_dashboard" />} />
        <Route path="/audio-interview" element={<ProtectedRoute element={<AudioInterview />} requiredPermission="view_dashboard" />} />
        <Route path="/text-interview" element={<ProtectedRoute element={<TextInterview />} requiredPermission="view_dashboard" />} />
        <Route path="/live-interview-result" element={<ProtectedRoute element={<LiveQnaResult />} requiredPermission="view_dashboard" />} />
        <Route path="/candidates" element={<ProtectedRoute element={<Candidates />} requiredPermission="view_dashboard" />} />
        <Route path="/team" element={<ProtectedRoute element={<Team />} requiredPermission="view_dashboard  " />} />
        <Route path="/add-member" element={<ProtectedRoute element={<AddTeamMember />} requiredPermission="view_dashboard" />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} requiredPermission="view_dashboard" />} />
      </Route>
    </Routes>
  );
};

export default RouteList;
