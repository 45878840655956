import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from './reducers/authReducers';
import { loadingReducer } from './reducers/loadingReducer';
import { getEvaluatedResumeDetailReducer, getLiveQnaDetailsReducer, getResultReducer } from './reducers/jobReducers';

const rootReducer = combineReducers({
    loading: loadingReducer,
    auth: authReducer,
    liveQnaDetails: getLiveQnaDetailsReducer,
    results: getResultReducer,
    evaluatedResumeDetail: getEvaluatedResumeDetailReducer,
});

const initialState = {
    auth: { userInfo: null },
    liveQnaDetails: {},
    results: {},
    evaluatedResumeDetail: {},
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
});

export default store;
