import React from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const EvaluationResultModal = ({ isOpen, onClose, data, isQnaLoading, submitNext, GoBack}) => {
  if (!isOpen) return null;

  if (!data) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75 transition-opacity duration-300">
        <div className="relative bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-md transform transition-transform duration-300">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl font-bold"
          >
            &times;
          </button>
          <div className="text-center">
            <h2 className="text-2xl font-bold text-red-700 mb-4">Error</h2>
            <p className="text-gray-700 mb-4">No evaluation data available.</p>
          </div>
        </div>
      </div>
    );
  }

  const { match_score, skills_match, experience_match, education_match } = data;
  const correctNumber = (num) => {
    if(num < 1) {
        return num * 100;
    }
    return num;
}

  const renderMessage = () => {
    if (correctNumber(match_score) >= 60) {
      return (
        <div className="text-center">
          <FaCheckCircle className="text-green-500 w-8 h-8 mx-auto mb-1 md:mb-4 animate-bounce" />
          <h2 className="text-xl md:text-3xl font-bold text-green-700 mb-2 md:mb-4">Congratulations!</h2>
          <p className="text-gray-700 mb-2 md:mb-4">You have been shortlisted for online interview.</p>
          <button className="px-6 py-3 bg-primary-500 text-white font-semibold rounded-full shadow-lg hover:bg-primary-600 transition-all duration-300" onClick={submitNext}>
              {isQnaLoading ? 'Creating Qna...' : 'Proceed to Next Round'}
              
          </button>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <FaTimesCircle className="text-red-500 w-8 h-8 mx-auto mb-1 md:mb-4 animate-bounce" />
          <h2 className="text-xl md:text-3xl font-bold text-red-700 mb-2 md:mb-4">Not Selected</h2>
          <p className="text-gray-700 mb-2 md:mb-4">We regret to inform you that you have not been shortlisted for the interview. Please try again later.</p>
        </div>
      );
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75 transition-opacity duration-300">
      <div className="relative bg-white p-2 md:p-10 rounded-2xl shadow-xl w-11/12 max-w-xl transform transition-transform duration-300">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl font-bold"
        >
          &times;
        </button>
        {renderMessage()}
        <div className="mt-2 md:mt-6">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Evaluation Details</h3>
          <div className="grid grid-cols-2 gap-2 md:gap-4">
            <div className="p-2 md:p-4 bg-gradient-to-r from-green-200 to-green-100 rounded-lg shadow-inner text-center">
              <span className="block text-lg font-semibold text-gray-700">Match Score</span>
              <span className="block text-3xl font-bold text-green-800">{correctNumber(match_score)}%</span>
            </div>
            <div className="p-2 md:p-4 bg-gradient-to-r from-primary-200 to-primary-100 rounded-lg shadow-inner text-center">
              <span className="block text-lg font-semibold text-gray-700">Skills Match</span>
              <span className="block text-3xl font-bold text-primary-800">{correctNumber(skills_match)}%</span>
            </div>
            <div className="p-2 md:p-4 bg-gradient-to-r from-yellow-200 to-yellow-100 rounded-lg shadow-inner text-center">
              <span className="block text-lg font-semibold text-gray-700">Experience Match</span>
              <span className="block text-3xl font-bold text-yellow-800">{correctNumber(experience_match)}%</span>
            </div>
            <div className="p-2 md:p-4 bg-gradient-to-r from-purple-200 to-purple-100 rounded-lg shadow-inner text-center">
              <span className="block text-lg font-semibold text-gray-700">Education Match</span>
              <span className="block text-3xl font-bold text-purple-800">{correctNumber(education_match)}%</span>
            </div>
          </div>
        </div>
        <div className="mt-6 relatice text-center">
        <button className="mx-auto text-center px-6 py-3 bg-primary-500 text-white font-semibold rounded-full shadow-lg hover:bg-primary-600 transition-all duration-300" onClick={GoBack}>
          Go Back              
        </button>
        </div>
      </div>
    </div>
  );
};

EvaluationResultModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default EvaluationResultModal;
