import { 
    LIVEQNA_DETAILS_LOADING,
    LIVEQNA_DETAILS_SUCCESS,
    LIVEQNA_DETAILS_FAIL,

    GET_RESULT_LOADING,
    GET_RESULT_SUCCESS,
    GET_RESULT_FAIL,

    EVALUATED_RESUME_DETAIL_LOADING,
    EVALUATED_RESUME_DETAIL_SUCCESS,
    EVALUATED_RESUME_DETAIL_FAIL,
} from '../constants/jobConstants'  

export const getLiveQnaDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case LIVEQNA_DETAILS_LOADING:
            return { loading: true };

        case LIVEQNA_DETAILS_SUCCESS:
            return { loading: false, liveQnaDetails: action.payload };

        case LIVEQNA_DETAILS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const getResultReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_RESULT_LOADING:
            return { loading: true };

        case GET_RESULT_SUCCESS:
            return { loading: false, results: action.payload };

        case GET_RESULT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const getEvaluatedResumeDetailReducer = (state = {}, action) => {
    switch (action.type) {
        case EVALUATED_RESUME_DETAIL_LOADING:
            return { loading: true };

        case EVALUATED_RESUME_DETAIL_SUCCESS:
            return { loading: false, evaluatedResumeDetail: action.payload };

        case EVALUATED_RESUME_DETAIL_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};