import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaLock } from "react-icons/fa";
import { createLiveQna, getEvaluatedResumeDetails } from '../../../redux/actions/jobActions';
import Loading from '../../../Dashboard/Components/Loading'; // Import the Loading component

const CandidateDashboard = () => {
    const [evaluatedResumeDetails, setEvaluatedResumeDetails] = useState(null);
    const [evaluatedInterviewDetails, setEvaluatedInterviewDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0); // Add progress state for Loading component
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleResumeEvaluation = () => {
        navigate('/resume-evaluation');
    };

    const handleResumeEvaluated = (id) => {
        navigate('/resume-evaluation-result', { state: { id } });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const res = await dispatch(getEvaluatedResumeDetails(null, user.id));
                if (res.resume_evaluation && res.resume_evaluation.id !== null) {
                    setEvaluatedResumeDetails(res.resume_evaluation);
                }

                if (res.interview_qa && res.interview_qa.id) {
                    setEvaluatedInterviewDetails(res.interview_qa);
                }
            } catch (error) {
                console.error('Failed to fetch evaluation details', error);
            }
        };
        fetchData();
    }, [dispatch]);

    const correctNumber = (num) => (num < 1 ? num * 100 : num);

    const createQna = async () => {
        const userDetail = JSON.parse(localStorage.getItem('user'));
        const formData = {
            user: userDetail.id,
            id: null,
            job: evaluatedResumeDetails.job,
            level: 1,
            interview_type: 1,
            resume: evaluatedResumeDetails.id,
        };
        setIsLoading(true);

        try {
            const fakeProgress = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        clearInterval(fakeProgress);
                        return 100;
                    }
                    return prevProgress + 1;
                });
            }, 100);

            const res = await dispatch(createLiveQna(formData));
            if (res) {
                localStorage.removeItem('liveQnaList');
                navigate('/interview', { state: { id: res.id } });
            }
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const checkResult = () => {
        navigate('/interview-result', { state: { id: evaluatedInterviewDetails.id } });
    }

    return (
        <div className="flex justify-center min-h-screen bg-gray-50 py-8">
            {isLoading && <Loading progress={progress} message="Creating Q&A session..." />} {/* Render Loading component when isLoading is true */}
            <div className="w-full md:max-w-7xl p-4">
                <main className="bg-white rounded-xl shadow-lg p-8 md:py-24 md:px-24">
                    <section className="text-center mb-12">
                        <h1 className="text-3xl font-bold text-gray-700">Dashboard</h1>
                        <p className="text-gray-500">Manage your resume evaluation and interview process.</p>
                    </section>
                    <section className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="rounded-lg border border-gray-300 bg-white p-6 shadow-lg relative overflow-hidden">
                            <div className="absolute inset-0 bg-gradient-to-r from-primary-50 to-white opacity-50 z-0"></div>
                            {evaluatedResumeDetails && evaluatedResumeDetails.id ? <div className="relative z-10 text-center">
                                <h3 className="text-xl md:text-2xl mb-4 text-gray-600 font-semibold">
                                    {evaluatedResumeDetails
                                        ? `Your resume ${correctNumber(evaluatedResumeDetails.match_score) < 60 ? 'was not' : ''} selected`
                                        : 'Evaluate your Resume'}
                                </h3>
                                <button
                                    onClick={evaluatedResumeDetails ? () => handleResumeEvaluated(evaluatedResumeDetails.id) : handleResumeEvaluation}
                                    className="bg-primary-600 font-semibold text-white py-2 px-4 rounded-full hover:bg-primary-700 transition duration-300"
                                >
                                    {evaluatedResumeDetails ? 'Check Now' : 'Evaluate Now'}
                                </button>
                            </div> : <div className="space-y-3 text-center  relative z-10">
                                <h3 className='text-xl md:text-2xl mb-4 text-center text-gray-600 font-semibold'>Evaluate your Resume</h3>
                                <button
                                    onClick={() => handleResumeEvaluation()}
                                    className="bg-primary-600 font-semibold text-white py-2 px-4 rounded-full hover:bg-primary-700 transition duration-300"
                                >
                                    Evaluate Now
                                </button>
                            </div>}
                            <div className="absolute bottom-0 right-0 w-24 h-24 bg-gradient-to-br from-primary-300 to-primary-500 opacity-30 rounded-full transform translate-x-1/2 translate-y-1/2 z-0"></div>
                        </div>
                        <div className="rounded-lg border border-gray-300 bg-white p-6 shadow-lg relative overflow-hidden">
                            <div className="absolute inset-0 bg-gradient-to-r from-primary-50 to-white opacity-50 z-0"></div>
                            <div className="relative z-10 text-center">
                                <h3 className="text-xl md:text-2xl mb-4 text-gray-600 font-semibold">
                                    {evaluatedResumeDetails && evaluatedInterviewDetails.performance
                                        ? evaluatedInterviewDetails && parseInt(evaluatedInterviewDetails.performance) >= 60
                                            ? "Congratulations! You've Selected to the Next Round."
                                            : 'Sorry you did not qualify for the next round'
                                        : 'Live Interview'}
                                </h3>
                                {evaluatedResumeDetails && correctNumber(evaluatedResumeDetails.match_score) >= 60 ? (
                                    evaluatedInterviewDetails && parseInt(evaluatedInterviewDetails.performance) >= 0 ? (
                                        <button
                                            onClick={checkResult}
                                            className="bg-primary-600 font-semibold text-white py-2 px-4 rounded-full hover:bg-primary-700 transition duration-300"
                                        >
                                            Check Result
                                        </button>
                                    ) : (
                                        <button
                                            onClick={createQna}
                                            className="bg-primary-600 font-semibold text-white py-2 px-4 rounded-full hover:bg-primary-700 transition duration-300"
                                        >
                                            {isLoading ? 'Creating Qna...' : 'Proceed to Next Round'}
                                        </button>
                                    )
                                ) : (
                                    <FaLock size={40} className="text-gray-400 mx-auto" />
                                )}
                            </div>
                            <div className="absolute bottom-0 right-0 w-24 h-24 bg-gradient-to-br from-primary-300 to-primary-500 opacity-30 rounded-full transform translate-x-1/2 translate-y-1/2 z-0"></div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default CandidateDashboard;
