import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { createJob, updateJob, fetchJobDetails } from '../../../redux/actions/jobActions';
import { toast } from 'react-toastify';
import { FaWandMagicSparkles } from "react-icons/fa6";
import CommonEditor from '../../Components/Editor';
import AIJobModal from './AIJobModal';
import axiosInstance from '../../../Common/axiosInstance';
import Loading from '../../Components/Loading';

const AddEditJob = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpenForm, setIsModalOpenForm] = useState(false);
    const [aiJobDescription, setAiJobDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const jobId = location.state?.id;

    useEffect(() => {
        if (jobId) {
            setIsEditing(true);
            dispatch(fetchJobDetails(jobId)).then((job) => {
                setTitle(job.title);
                setDescription(job.description);
            }).catch((error) => {
                toast.error('Failed to load job details');
            });
        }
    }, [jobId, dispatch]);

    const validateFields = () => {
        let valid = true;

        if (title.length > 100) {
            setTitleError('Job title cannot exceed 100 characters');
            valid = false;
        } else if (!title) {
            setTitleError('Job title is required');
            valid = false;
        } else {
            setTitleError('');
        }

        if (description.length > 3000) {
            setDescriptionError('Job description cannot exceed 3000 characters');
            valid = false;
        } else if (!description) {
            setDescriptionError('Job description is required');
            valid = false;
        } else {
            setDescriptionError('');
        }

        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        }

        setIsSubmitting(true);
        const jobData = {
            title,
            description
        };
        try {
            let res;
            if (isEditing) {
                res = await dispatch(updateJob(jobId, jobData));
            } else {
                res = await dispatch(createJob(jobData));
            }
            setTitle('');
            setDescription('');
            if (res) {
                toast.success(`Job ${isEditing ? 'updated' : 'added'} successfully`);
                localStorage.removeItem('jobList');
                navigate('/jobList');
            } else {
                toast.error(`Failed to ${isEditing ? 'update' : 'add'} job`);
            }
        } catch (error) {
            toast.error('An error occurred');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleGenerateJobWithAI = () => {
        setIsModalOpenForm(true);
    };

    const handleApplyAIJobDescription = async (data) => {
        setDescription(aiJobDescription);
        setTitle(data.jobTitle);
        setIsModalOpenForm(false);
        setIsLoading(true);
        const fakeProgress = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    clearInterval(fakeProgress);
                    return 100;
                }
                return prevProgress + 1;
            });
          }, 100);
        const response = await axiosInstance.post('/hr/generate-job/', data); 
        if(response.data) {
            setAiJobDescription(response.data.job_description);  
        } 
        setIsLoading(false);
    };

    return (
        <div className='min-h-screen bg-gray-100 pt-8'>
          {isLoading && <Loading progress={progress} message="Generating titles, please wait..." />}

            <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-800 mb-2">{isEditing ? 'Edit Job' : 'Create New Job'}</h1>
            <p className="text-lg text-center text-gray-500 mb-6">
                {isEditing ? 'Make changes to the job details below' : 'Fill in the details to create a new job listing'}
            </p>

            <div className="flex justify-center items-center py-8">
                <main className="w-full max-w-4xl mx-4 md:mx-0 p-8 bg-white rounded-lg shadow-lg">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-gray-700 font-semibold mb-2" htmlFor="title">Job Title</label>
                            <input
                                type="text"
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                maxLength="100"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
                                required
                            />
                            {titleError && (
                                <div className="text-red-500 text-sm mt-1">{titleError}</div>
                            )}
                            <div className="text-right text-gray-500 text-sm mt-1">
                                {title.length}/100
                            </div>
                        </div>
                        <div>
                            <div className="md:flex md:justify-between items-center">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="description">Job Description</label>
                                <button
                                    type="button"
                                    onClick={handleGenerateJobWithAI}
                                    className="flex px-2 md:px-4 py-1 md:py-2 mb-2 bg-primary-500 text-white font-semibold rounded-lg shadow-lg hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                                >
                                    <FaWandMagicSparkles className="mt-1 mr-2" /> Generate Job with AI
                                </button>
                            </div>
                            <CommonEditor
                                value={description}
                                onChange={(value) => setDescription(value)}
                                className="h-96"
                                maxLength="3000"
                            />
                            {descriptionError && (
                                <div className="text-red-500 text-sm mt-1">{descriptionError}</div>
                            )}
                            <div className="text-right text-gray-500 text-sm mt-1">
                                {description.length}/3000
                            </div>
                        </div>
                        <div className="flex justify-center ">
                            <button
                                type="submit"
                                className="px-4 py-2 bg-primary-500 text-white font-semibold rounded-lg shadow-lg hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : isEditing ? 'Update Job' : 'Add Job'}
                            </button>
                        </div>
                    </form>
                </main>
            </div>
            
            <AIJobModal isOpen={isModalOpenForm} onClose={() => setIsModalOpenForm(false)} onGenerate={handleApplyAIJobDescription} />
        </div>
    );
};

export default AddEditJob;
