import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createLiveQna, getEvaluatedResumeDetails } from '../../../redux/actions/jobActions';
import { toast, ToastContainer } from 'react-toastify';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const ResumeEvResult = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state?.id;

    const [loading, setLoading] = useState(true);
    const evaluationDetails = useSelector(state => state.evaluatedResumeDetail.evaluatedResumeDetail); // Adjust this based on your Redux state structure

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getEvaluatedResumeDetails(id, null));
            } catch (error) {
                console.error('Failed to fetch evaluation details', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!evaluationDetails) {
        return <div>No evaluation data available</div>;
    }

    const { match_score, skills_match, experience_match, education_match, job } = evaluationDetails;

    const handleSubmit = async () => {
        const formData = {
            id : id,
            job: job,
            interview_type: 'HR',
        };

        try {
            const res = await dispatch(createLiveQna(formData)); 
            if (res) {
                localStorage.removeItem('qnaList');
                navigate('/live-interview', { state: { id: res.id }});
            }
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setLoading(false);
        }
    }   

    const correctNumber = (num) => {
        if(num < 1) {
            return num * 100;
        }
        return num;
    }

    const renderMessage = () => {
        if (correctNumber(match_score) >= 60) {
            return (
                <div className="text-center">
                    <FaCheckCircle className="text-green-500 w-16 h-16 mx-auto mb-4" />
                    <h2 className="text-3xl font-bold text-green-700 mb-4">Congratulations!</h2>
                    <p className="text-gray-700 mb-4">You have been shortlisted for online interview.</p>
                    {/* <button
                        onClick={() => handleSubmit()}
                        className="px-6 py-3 bg-primary-500 text-white font-semibold rounded-full shadow-lg hover:bg-primary-600 transition-all duration-300"
                    >
                        Proceed to Next Round
                    </button> */}
                </div>
            );
        } else {
            return (
                <div className="text-center">
                    <FaTimesCircle className="text-red-500 w-16 h-16 mx-auto mb-4" />
                    <h2 className="text-3xl font-bold text-red-700 mb-4">Not Selected</h2>
                    <p className="text-gray-700 mb-4">We regret to inform you that you have not been shortlisted for the interview. Please try again later.</p>
                </div>
            );
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 py-8">
            <main className="w-full max-w-lg p-10 bg-white rounded-2xl shadow-xl">
                <div className="text-center">
                    {renderMessage()}
                </div>
                <div className="mt-6">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">Evaluation Details</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="p-4 bg-gradient-to-r from-green-200 to-green-100 rounded-lg shadow-inner text-center">
                            <span className="block text-lg font-semibold text-gray-700">Match Score</span>
                            <span className="block text-3xl font-bold text-green-800">{correctNumber(match_score)}%</span>
                        </div>
                        <div className="p-4 bg-gradient-to-r from-primary-200 to-primary-100 rounded-lg shadow-inner text-center">
                            <span className="block text-lg font-semibold text-gray-700">Skills Match</span>
                            <span className="block text-3xl font-bold text-primary-800">{correctNumber(skills_match)}%</span>
                        </div>
                        <div className="p-4 bg-gradient-to-r from-yellow-200 to-yellow-100 rounded-lg shadow-inner text-center">
                            <span className="block text-lg font-semibold text-gray-700">Experience Match</span>
                            <span className="block text-3xl font-bold text-yellow-800">{correctNumber(experience_match)}%</span>
                        </div>
                        <div className="p-4 bg-gradient-to-r from-purple-200 to-purple-100 rounded-lg shadow-inner text-center">
                            <span className="block text-lg font-semibold text-gray-700">Education Match</span>
                            <span className="block text-3xl font-bold text-purple-800">{correctNumber(education_match)}%</span>
                        </div>
                    </div>
                    <div className="mt-12 relatice text-center">
                        <Link className="mx-auto text-center px-6 py-3 bg-primary-500 text-white font-semibold rounded-full shadow-lg hover:bg-primary-600 transition-all duration-300" to='/candidate-dashboard'>
                            Go Back              
                        </Link>
                    </div>
                </div>
                <ToastContainer />
            </main>
        </div>
    );
};

export default ResumeEvResult;
