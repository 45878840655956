import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFileInvoice } from 'react-icons/fa';
import Tabs from './Components/Tabs';
import DashboardCard from './Components/DashboardCard';
import SalesGraphCard from './Components/SalesGraphCard';
import CustomerListCard from './Components/CustomerListCard';
import { useDispatch } from 'react-redux';
import { fetchDashboardData } from '../redux/actions/jobActions';
import { set } from 'react-hook-form';

const DashboardHome = () => {

  const [data, setData] = useState({
    resume_evaluations_count: 0,
    interviews_count: 0,
    qualified_candidates_count: 0,
    non_selected_candidates_count: 0.00,
    invoice_trend: 0.00,
    candidates_list: 0.00,
  });

  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const candidateData = await dispatch(fetchDashboardData());
        if (candidateData) {
          setData({
            resume_evaluations_count: candidateData.resume_evaluations_count, 
            interviews_count: candidateData.interviews_count,
            qualified_candidates_count: candidateData.qualified_candidates_count,
          });
          setSelectedCandidate(candidateData.selected_candidates_data);
          setCustomerData(candidateData.candidates_list);
        } else {
          console.log('Error fetching data');
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex">

      <div className="flex-1">
        <main className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 mb-6 ">
            <div>
              <h1 className="text-2xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">HRAI Insight</h1>
              <p className="text-gray-500 dark:text-gray-300">
              Revolutionize recruitment with AI-driven efficiency!
              </p>
            </div>
            <div>
              <div className="flex justify-enter mt-4 md:justify-end">
                <Link to="/add-job" className="flex items-center px-8 py-2 text-white bg-primary-600 rounded-md shadow-md hover:from-primary-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                  <FaFileInvoice className="mr-2" />
                  Add Job
                </Link>
              </div>
            </div>
          </div>

          {/* <Tabs /> */}
          <div className="grid grid-cols-1 mt-5">
            <DashboardCard data={data} />
          </div>
          <div className="grid gap-5 grid-cols-1 md:grid-cols-10 mt-5">
            <div className="md:col-span-7">
              {selectedCandidate && <SalesGraphCard selectedCandidatesData={selectedCandidate} />}
            </div>
            <div className="md:col-span-3">
              <CustomerListCard customerData={customerData} />
            </div>
          </div>

        </main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default DashboardHome;
