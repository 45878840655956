import React from 'react';

const ContactHero = () => {
  return (
    <section className="py-14 lg:py-24 text-center bg-gradient-to-r from-primary-100 to-green-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-5 md:text-5xl">
          Connect with <span className="text-primary-600">HR.AI Box</span>
        </h1>
        <p className="text-base mb-9 max-w-md mx-auto">
          Whether you have a question, need support, or want to explore our solutions, we're here to assist you. Let's make hiring effortless together!
        </p>
      </div>
    </section>
  );
};

export default ContactHero;