import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaSearch, FaRegTrashAlt, FaPlus, FaFileInvoice } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getEvaluatedResumes } from '../../../redux/actions/jobActions';
import { FILE_URL } from '../../../redux/env'; // Assuming this is where your file URLs are stored

const EvaluatedList = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [filter, setFilter] = useState('');
    const [resumes, setResumes] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const itemsPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await dispatch(getEvaluatedResumes());
                setResumes(data);
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    const handleView = (resumeId) => {
        navigate('/evaluated-result', { state: { id: resumeId } });
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const filteredResumes = Array.isArray(resumes) ? resumes.filter((resume) =>
        (resume.job_title && resume.job_title.toLowerCase().includes(filter.toLowerCase())) ||
        (resume.created_at && resume.created_at.includes(filter))
    ) : [];

    const offset = currentPage * itemsPerPage;
    const currentResumes = filteredResumes.slice(offset, offset + itemsPerPage);

    return (
        <div className="justify-center items-center ">
            <main className="p-4">
                <div className="grid grid-col-1 mt-6 mb-6 ">
                    <h1 className="text-2xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Evaluated Resume</h1>
                    <p className="text-gray-500 dark:text-gray-300">
                        List of evaluated resumes!
                    </p>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md">
                    <div className="flex justify-between items-center mb-6">
                        <div className="relative w-1/3">
                            <FaSearch className="absolute top-2.5 left-3 text-gray-500" />
                            <input
                                type="text"
                                placeholder="Filter resumes"
                                className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:ring focus:ring-primary-200 focus:border-primary-500"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>
                        {/* <Link to="/candidate-resume-evaluation" className="flex items-center px-8 py-2 text-white bg-primary-600 rounded-md shadow-md hover:from-primary-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                            <FaPlus className="mr-2" />
                            Add Resume Evaluation
                        </Link> */}
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white shadow-md rounded-lg">
                            <thead className="bg-gradient-to-r from-primary-100 to-indigo-100">
                                <tr>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Job</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Email</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Name</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Match Score</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Resume File</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Date</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentResumes.length > 0 ? (
                                    currentResumes.map((resume, index) => (
                                        <tr key={index} className="border-b hover:bg-gray-50">
                                            <td className="py-3 px-5 text-sm text-gray-800">{resume.job_title}</td>
                                            <td className="py-3 px-5 text-sm text-gray-800">{resume.user_email}</td>
                                            <td className="py-3 px-5 text-sm text-gray-800">{resume.user_name}</td>
                                            <td className="py-3 px-5 text-sm text-gray-800">{resume.match_score}</td>
                                            <td className="py-3 px-5 text-sm text-gray-800">
                                                <a href={FILE_URL + resume.resume_file} className='text-primary-700 font-semibold' target='_blank' rel='noopener noreferrer'>
                                                    {resume.resume_file?.replace("/media/resumes/", "")}
                                                </a>
                                            </td>
                                            <td className="py-3 px-5 text-sm text-gray-800">{formatDate(resume.created_at)}</td>
                                            <td className="py-3 px-5 text-sm">
                                                <button onClick={() => handleView(resume.id)} className="px-4 py-1 text-primary-600 border border-primary-600 bg-white hover:bg-primary-600 hover:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">View</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="py-10 text-center">
                                            <div className="flex flex-col items-center">
                                                <FaFileInvoice className="mb-3 text-6xl text-gray-400" />
                                                <p className="text-lg font-medium text-gray-600">No evaluated resumes found</p>
                                                <p className="text-sm text-gray-500">Try evaluating a new resume!</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-6">
                        {currentResumes.length > 0 && (
                            <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(filteredResumes.length / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'flex justify-center space-x-2'}
                                pageClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                previousClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                nextClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                breakClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                activeClassName={'bg-primary-600 text-white'}
                            />
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default EvaluatedList;
