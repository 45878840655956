import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

const AIJobModal = ({ isOpen, onClose, onGenerate }) => {
  const [jobTitle, setJobTitle] = useState('');
  const [jobPurpose, setJobPurpose] = useState('');
  const [experience, setExperience] = useState('');
  const [role, setRole] = useState('');

  const handleGenerate = () => {
    const jobDetails = { jobTitle, jobPurpose, experience, role };
    onGenerate(jobDetails);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75 transition-opacity duration-300">
      <div className="relative bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-3xl transform transition-transform duration-300">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl font-bold"
        >
          &times;
        </button>
        <div className="">
          <h2 className="text-xl font-semibold mb-4">Generate Job Description</h2>
          <form className="space-y-4">
            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8'>
              <div className="md:mt-1">
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="jobTitle">Job Title</label>
                <input
                  type="text"
                  id="jobTitle"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  placeholder="e.g., Software Engineer"
                  className="w-full px-4 py-2 md:py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                  required
                />
              </div>
              <div className="md:mt-1">
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="jobPurpose">Job Purpose</label>
                <input
                  type="text"
                  id="jobPurpose"
                  value={jobPurpose}
                  onChange={(e) => setJobPurpose(e.target.value)}
                  placeholder="e.g., Develop and maintain software applications"
                  className="w-full px-4 py-2 md:py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                  required
                />
              </div>
              <div className="md:mt-1">
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="experience">Experience</label>
                <input
                  type="text"
                  id="experience"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  placeholder="e.g., 3-5 years in software development"
                  className="w-full px-4 py-2 md:py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                  required
                />
              </div>
              <div className="md:mt-1">
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="role">Role</label>
                <input
                  type="text"
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="e.g., Team Lead"
                  className="w-full px-4 py-2 md:py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                  required
                />
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                onClick={handleGenerate}
                className="px-4 mt-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-300"
              >
                Generate Description
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

AIJobModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
};

export default AIJobModal;
