import React, { useEffect, useState } from 'react';
import { FaUserEdit, FaEnvelope, FaPhone, FaMapMarkerAlt, FaSave, FaTimes, FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../Components/Button';
import Container from '../../Components/Container';
import ContentHeader from '../../Components/ContentHeader';
import Card from '../../Components/Card'; 
import { updateProfile, userProfile } from '../../redux/actions/authActions';

const Profile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [profile, setProfile] = useState({
        name: 'No Name',
        email: user.email,
        role: 'Admin',
        address: 'No Address'
    });

    // State variables for error messages
    const [errors, setErrors] = useState({
        name: '',
        address: ''
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await dispatch(userProfile());
                if(data) {
                    setProfile({
                        name: data.name || "",
                        email: data.email || "",
                        role: 'Admin',
                        address: data.address || ""
                    });
                }
            } catch (error) {
                console.log(error.message);
            } 
        };
      
        fetchData();
    }, [dispatch]);

    const validate = () => {
        const newErrors = {};
        if (profile.name.length > 50) {
            newErrors.name = 'Name cannot exceed 50 characters';
        } else if (!profile.name) {
            newErrors.name = 'Name is required';
        }

        if (profile.address.length > 200) {
            newErrors.address = 'Address cannot exceed 200 characters';
        } else if (!profile.address) {
            newErrors.address = 'Address is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));

        // Clear error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                dispatch(updateProfile(profile)).then((res) => {
                    toast.success('Profile updated successfully');
                });
                setIsEditing(false);
            } catch (error) {
                console.error('Profile update error:', error);
            }
        } else {
            toast.error('Please fix the errors in the form');
        }
    };

    return (
        <Container>
            <ContentHeader heading="Settings" style="text-left py-8" subheading="Your great subtitle goes here" />
            <Card className="bg-gradient-to-r from-primary-50 to-primary-50 shadow-lg rounded-xl py-12 px-6">
                {isEditing ? (
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="md:mx-20 mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                            <div className="mt-4 md:col-span-1">
                                <label className="block text-gray-700 font-semibold mb-1" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={profile.name}
                                    onChange={handleChange}
                                    className="w-full px-4 py-4 border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-400"
                                    required
                                />
                                {errors.name && (
                                    <div className="text-red-500 text-sm mt-1">{errors.name}</div>
                                )}
                                <div className="text-right text-gray-500 text-sm mt-1">
                                    {profile.name.length}/50
                                </div>
                            </div>
                            <div className="mt-4 md:col-span-1">
                                <label className="block text-gray-700 font-semibold mb-1" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={profile.email}
                                    onChange={handleChange}
                                    className="w-full px-4 py-4 border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-400"
                                    disabled
                                />
                            </div>
                            <div className="mt-4 md:col-span-1">
                                <label className="block text-gray-700 font-semibold mb-1" htmlFor="role">
                                    Role
                                </label>
                                <input
                                    type="text"
                                    id="role"
                                    name="role"
                                    value={profile.role}
                                    onChange={handleChange}
                                    className="w-full px-4 py-4 border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-400"
                                    disabled
                                />
                            </div>
                            <div className="mt-4 md:col-span-2">
                                <label className="block text-gray-700 font-semibold mb-1" htmlFor="address">
                                    Address
                                </label>
                                <textarea
                                    id="address"
                                    name="address"
                                    value={profile.address}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-400"
                                    rows="3"
                                    required
                                ></textarea>
                                {errors.address && (
                                    <div className="text-red-500 text-sm mt-1">{errors.address}</div>
                                )}
                                <div className="text-right text-gray-500 text-sm mt-1">
                                    {profile.address.length}/200
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center space-x-4">
                            <Button
                                type="submit"
                                className="flex items-center px-6 py-3 font-semibold text-white bg-gradient-to-r from-primary-400 to-primary-600 hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-400 rounded-full shadow-lg"
                            >
                                <FaSave className="mr-2" />
                                Save Changes
                            </Button>
                            <Button
                                type="button"
                                className="flex items-center border font-semibold border-red-700 px-6 py-3 text-red-700 bg-white shadow-md hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-400 rounded-full"
                                onClick={() => setIsEditing(false)}
                            >
                                <FaTimes className="mr-2" />
                                Cancel
                            </Button>
                        </div>
                    </form>
                ) : (
                    <div className="space-y-6">
                        <div className="flex justify-center">
                            <FaUser className="text-primary-400 p-2 text-6xl mb-4 border-4 border-primary-400 rounded-full" />
                        </div>
                        <div className="md:mx-20 mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                            <div className="flex items-center space-x-4 bg-white rounded-lg p-4 shadow-md">
                                <FaUserEdit className="text-xl text-primary-600" />
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-700">Name</h3>
                                    <p className="text-gray-900">{profile.name}</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4 bg-white rounded-lg p-4 shadow-md">
                                <FaEnvelope className="text-xl text-primary-600" />
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-700">Email</h3>
                                    <p className="text-gray-900">{profile.email}</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4 bg-white rounded-lg p-4 shadow-md">
                                <FaUserEdit className="text-xl text-primary-600" />
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-700">Role</h3>
                                    <p className="text-gray-900">{profile.role}</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4 bg-white rounded-lg p-4 shadow-md">
                                <FaMapMarkerAlt className="text-xl text-primary-600" />
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-700">Address</h3>
                                    <p className="text-gray-900">{profile.address}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <Button
                                type="button"
                                className="flex items-center font-semibold mt-8 px-6 py-3 text-white bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-400 rounded-full shadow-lg"
                                onClick={() => setIsEditing(true)}
                            >
                                <FaUserEdit className="mr-2" />
                                Edit Profile
                            </Button>
                        </div>
                    </div>
                )}
            </Card>
            <ToastContainer />
        </Container>
    );
};

export default Profile;
