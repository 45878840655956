import React from 'react';

const ServiceHeroSection = () => {
  return (
    <section className="py-14 lg:py-24 text-center bg-gradient-to-r from-fuchsia-100 to-cyan-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-5 md:text-5xl">
        Unlock the Power of <span className="text-primary-600">Invoice Automation</span>
        </h1>
        <p className="text-base text-gray-500 mb-9 max-w-lg mx-auto">
          Discover how our advanced invoice extraction and data visualization services can revolutionize your business operations.
        </p>
      </div>
    </section>
  );
};

export default ServiceHeroSection;
