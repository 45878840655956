import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { getJobList, evaluateResume } from '../../../redux/actions/jobActions';
import EvaluationResultModal from './EvaluationResultModal';

const Evaluation = () => {
    const dispatch = useDispatch();
    const [jobList, setJobList] = useState([]);
    const [selectedJob, setSelectedJob] = useState('');
    const [resumeFile, setResumeFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const jobs = await dispatch(getJobList());
                setJobList(jobs);
            } catch (error) {
                console.error('Failed to fetch jobs', error);
            }
        };
        fetchJobs();
    }, [dispatch]);

    const handleJobChange = (e) => {
        setSelectedJob(e.target.value);
    };

    const handleFileChange = (e) => {
        setResumeFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedJob || !resumeFile) {
            toast.error('All fields are required');
            return;
        }
        setIsLoading(true);

        const formData = new FormData();
        formData.append('job', selectedJob);
        formData.append('resume_file', resumeFile);

        try {
            const response = await dispatch(evaluateResume(formData));
            if (response) {
                setModalData(response);
                setIsModalOpen(true);
            } else {
                toast.error('Failed to evaluate resume');
            }
        } catch (error) {
            console.error('Error during evaluation:', error);
            toast.error('An error occurred during evaluation');
        } finally {
            setIsLoading(false);
        }
    };

    console.log('modalData', modalData, typeof modalData);

    return (
        <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-primary-100 to-primary-200 py-8">
            <main className="w-full max-w-lg p-8 bg-white rounded-xl shadow-xl transform transition-all duration-500 hover:scale-105">
                <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-6">Resume Evaluation</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="job">
                            Select Job
                        </label>
                        <select
                            id="job"
                            value={selectedJob}
                            onChange={handleJobChange}
                            className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
                            required
                        >
                            <option value="">Select a job</option>
                            {jobList.map((job) => (
                                <option key={job.id} value={job.id}>
                                    {job.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="resume">
                            Upload Resume
                        </label>
                        <input
                            type="file"
                            id="resume"
                            accept=".pdf,.doc,.docx"
                            onChange={handleFileChange}
                            className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full px-6 py-3 bg-gradient-to-r from-green-400 to-green-500 text-white font-bold rounded-full shadow-lg hover:from-green-500 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-300"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Submitting...' : 'Evaluate Resume'}
                    </button>
                </form>
                <ToastContainer />
            </main>
            {isModalOpen && (
                <EvaluationResultModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    data={modalData}
                />
            )}
        </div>
    );
};

export default Evaluation;
