export const INTERVIEW_TYPE_LOADING = 'INTERVIEW_TYPE_LOADING'
export const INTERVIEW_TYPE_SUCCESS = 'INTERVIEW_TYPE_SUCCESS'
export const INTERVIEW_TYPE_FAIL = 'INTERVIEW_TYPE_FAIL'

export const CREATE_JOB_LOADING = 'CREATE_JOB_LOADING'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_FAIL = 'CREATE_JOB_FAIL'

export const JOB_DETAIL_LOADING = 'JOB_DETAIL_LOADING'
export const JOB_DETAIL_SUCCESS = 'JOB_DETAIL_SUCCESS'
export const JOB_DETAIL_FAIL = 'JOB_DETAIL_FAIL'

export const JOB_LIST_LOADING = 'JOB_LIST_LOADING'
export const JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS'
export const JOB_LIST_FAIL = 'JOB_LIST_FAIL'

export const UPDATE_JOB_LOADING = 'UPDATE_JOB_LOADING'
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS'
export const UPDATE_JOB_FAIL = 'UPDATE_JOB_FAIL' 

export const EVALUATE_RESUME_LOADING = 'EVALUATE_RESUME_LOADING'
export const EVALUATE_RESUME_SUCCESS = 'EVALUATE_RESUME_SUCCESS'
export const EVALUATE_RESUME_FAIL = 'EVALUATE_RESUME_FAIL'

export const EVALUATED_RESUMES_LOADING = 'EVALUATED_RESUMES_LOADING'
export const EVALUATED_RESUMES_SUCCESS = 'EVALUATED_RESUMES_SUCCESS'
export const EVALUATED_RESUMES_FAIL = 'EVALUATED_RESUMES_FAIL'

export const EVALUATED_RESUME_DETAIL_LOADING = 'EVALUATED_RESUME_DETAIL_LOADING'
export const EVALUATED_RESUME_DETAIL_SUCCESS = 'EVALUATED_RESUME_DETAIL_SUCCESS'
export const EVALUATED_RESUME_DETAIL_FAIL = 'EVALUATED_RESUME_DETAIL_FAIL'

export const CREATE_LIVEQNA_LOADING = 'CREATE_LIVEQNA_LOADING'
export const CREATE_LIVEQNA_SUCCESS = 'CREATE_LIVEQNA_SUCCESS'
export const CREATE_LIVEQNA_FAIL = 'CREATE_LIVEQNA_FAIL'

export const LIVEQNA_DETAILS_LOADING = 'LIVEQNA_DETAILS_LOADING'
export const LIVEQNA_DETAILS_SUCCESS = 'LIVEQNA_DETAILS_SUCCESS'
export const LIVEQNA_DETAILS_FAIL = 'LIVEQNA_DETAILS_FAIL'

export const LIVEQNA_LIST_LOADING = 'LIVEQNA_LIST_LOADING'
export const LIVEQNA_LIST_SUCCESS = 'LIVEQNA_LIST_SUCCESS'
export const LIVEQNA_LIST_FAIL = 'LIVEQNA_LIST_FAIL'

export const GET_RESULT_LOADING = 'GET_RESULT_LOADING'
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS'
export const GET_RESULT_FAIL = 'GET_RESULT_FAIL' 

export const DASHBOARD_DATA_LOADING = 'DASHBOARD_DATA_LOADING'
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS'
export const DASHBOARD_DATA_FAIL = 'DASHBOARD_DATA_FAIL' 