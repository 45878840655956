import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchJobDetails } from '../../../redux/actions/jobActions';
import { FaCalendarAlt, FaBriefcase } from 'react-icons/fa';

const JobDetails = () => {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const jobId = location.state.jobId;

  useEffect(() => {
    const getJobDetails = async () => {
      try {
        const data = await dispatch(fetchJobDetails(jobId));
        setJob(data);
      } catch (error) {
        console.error('Error fetching job details:', error);
      } finally {
        setLoading(false);
      }
    };

    localStorage.setItem('previous_url', 'job-details');
    getJobDetails();
  }, [dispatch, jobId]);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (!job) {
    return <div className="text-center">Job not found</div>;
  }

  const applyNow = () => {
    navigate('/candidate-dashboard');
  };

  return (
    <div className="bg-gray-50 py-12">
      <div className="container mx-auto p-6 md:p-12 bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <FaBriefcase className="text-primary-500 text-3xl mr-3" />
            <div>
              <h1 className="text-3xl font-bold text-gray-800">{job.title}</h1>
              <div className="text-lg text-gray-600">{job.company}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 mb-6">
          <div className="flex items-center text-gray-600">
            <FaCalendarAlt className="mr-2" />
            <span>Posted on: {new Date(job.created_at).toLocaleDateString()}</span>
          </div>
        </div>
        <div className="text-gray-700 mb-6">
          {/* <h2 className="text-2xl font-semibold mb-3">Job Description</h2> */}
          <div dangerouslySetInnerHTML={{ __html: job.description }} />
        </div>
        <div className="text-center">
          <button
            className="bg-primary-600 text-white py-3 px-6 rounded-full font-semibold shadow-md hover:bg-primary-700 transition duration-300"
            onClick={applyNow}
          >
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
