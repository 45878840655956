import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];
const renderLabel = ({ name, value }) => `${name}: ${value}%`;

const PerformanceOverview = ({ pieData }) => {
    const allZero = pieData.every(item => item.value === 0);

    if (!pieData || pieData.length === 0) {
        return <p className="text-center">No data available</p>;
    }

    return (
        <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Performance Overview</h2>
            <div className="flex justify-center">
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={allZero ? [{ name: 'No Data', value: 1 }] : pieData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                            label={allZero ? () => 'No Data' : renderLabel}
                        >
                            {(allZero ? [{ name: 'No Data', value: 1 }] : pieData).map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={allZero ? '#E0E0E0' : COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default PerformanceOverview;
