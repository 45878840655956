import axiosInstance, { axiosInstanceWithMultipart } from '../../Common/axiosInstance';
import {
    INTERVIEW_TYPE_LOADING,
    INTERVIEW_TYPE_SUCCESS,
    INTERVIEW_TYPE_FAIL,

    CREATE_JOB_LOADING,
    CREATE_JOB_SUCCESS,
    CREATE_JOB_FAIL,

    JOB_LIST_LOADING,
    JOB_LIST_SUCCESS,
    JOB_LIST_FAIL,

    JOB_DETAIL_LOADING,
    JOB_DETAIL_SUCCESS,
    JOB_DETAIL_FAIL,

    UPDATE_JOB_LOADING,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAIL,  

    EVALUATE_RESUME_LOADING,
    EVALUATE_RESUME_SUCCESS,
    EVALUATE_RESUME_FAIL,

    EVALUATED_RESUMES_LOADING,
    EVALUATED_RESUMES_SUCCESS,
    EVALUATED_RESUMES_FAIL,

    EVALUATED_RESUME_DETAIL_LOADING,
    EVALUATED_RESUME_DETAIL_SUCCESS,
    EVALUATED_RESUME_DETAIL_FAIL,

    CREATE_LIVEQNA_LOADING,
    CREATE_LIVEQNA_SUCCESS,
    CREATE_LIVEQNA_FAIL,

    LIVEQNA_DETAILS_LOADING,
    LIVEQNA_DETAILS_SUCCESS,
    LIVEQNA_DETAILS_FAIL,

    LIVEQNA_LIST_LOADING,
    LIVEQNA_LIST_SUCCESS,
    LIVEQNA_LIST_FAIL,

    GET_RESULT_LOADING,
    GET_RESULT_SUCCESS,
    GET_RESULT_FAIL,

    DASHBOARD_DATA_LOADING,
    DASHBOARD_DATA_SUCCESS,
    DASHBOARD_DATA_FAIL,
} from '../constants/jobConstants';
import { toast } from 'react-toastify';

export const getInterviewType = () => async (dispatch) => {
    try {
        dispatch({ type: INTERVIEW_TYPE_LOADING });

        // const cachedData = localStorage.getItem('interveiwType');
        // if (cachedData) {
        //     const parsedData = JSON.parse(cachedData);
        //     const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        //     const currentTime = new Date().getTime();

        //     if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
        //         dispatch({
        //             type: INTERVIEW_TYPE_SUCCESS,
        //             payload: parsedData.data,
        //         });
        //         return parsedData.data;
        //     } else {
        //         localStorage.removeItem('interveiwType');
        //     }
        // }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/hr/interview-types/${userDetail.id}/`);  
        localStorage.setItem('interveiwType', JSON.stringify({ data, timestamp: new Date().getTime() }));      
        dispatch({
            type: INTERVIEW_TYPE_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: INTERVIEW_TYPE_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const fetchDashboardData = () => async (dispatch) => {
    try {
        dispatch({ type: DASHBOARD_DATA_LOADING });

        // const cachedData = localStorage.getItem('dashboardData');
        // if (cachedData) {
        //     const parsedData = JSON.parse(cachedData);
        //     const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        //     const currentTime = new Date().getTime();

        //     if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
        //         dispatch({
        //             type: DASHBOARD_DATA_SUCCESS,
        //             payload: parsedData.data,
        //         });
        //         return parsedData.data;
        //     } else {
        //         localStorage.removeItem('dashboardData');
        //     }
        // }

        const { data } = await axiosInstance.get(`/hr/dashboard-data/`);
        localStorage.setItem('dashboardData', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: DASHBOARD_DATA_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: DASHBOARD_DATA_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const createJob = (jobData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_JOB_LOADING }); 
        const { data } = await axiosInstance.post('/hr/create-job/', jobData);

        dispatch({
            type: CREATE_JOB_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: CREATE_JOB_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const getJobList = () => async (dispatch) => {
    try {
        dispatch({ type: JOB_LIST_LOADING });

        const cachedData = localStorage.getItem('jobList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: JOB_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('jobList');
            }
        }
        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const { data } = await axiosInstance.get(`/hr/jobs/`);
        localStorage.setItem('jobList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: JOB_LIST_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: JOB_LIST_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const fetchJobDetails = (pk) => async (dispatch) => {
    try {
        dispatch({ type: JOB_DETAIL_LOADING });
 
        const { data } = await axiosInstance.get(`/hr/job-details/${pk}/`);
        dispatch({
            type: JOB_DETAIL_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: JOB_DETAIL_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const updateJob = (jobId, jobData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_JOB_LOADING });

        const config = axiosInstanceWithMultipart();
        const { data } = await axiosInstance.put(`/hr/update-job/${jobId}/`, jobData, config);

        dispatch({
            type: UPDATE_JOB_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: UPDATE_JOB_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const deleteJob = (pk) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/hr/delete-job/${pk}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
}; 

export const evaluateResume = (formData) => async (dispatch) => {
    try {
        dispatch({ type: EVALUATE_RESUME_LOADING });
        const config = axiosInstanceWithMultipart();
        const { data } = await axiosInstance.post('/hr/evaluate-resume/', formData, config);

        dispatch({
            type: EVALUATE_RESUME_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: EVALUATE_RESUME_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const getEvaluatedResumes = () => async (dispatch) => {
    try {
        dispatch({ type: EVALUATED_RESUMES_LOADING });

        const cachedData = localStorage.getItem('evaluated_resumes');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: EVALUATED_RESUMES_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('evaluated_resumes');
            }
        }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/hr/evaluated-resumes/${userDetail.id}/`);  
        localStorage.setItem('evaluated_resumes', JSON.stringify({ data, timestamp: new Date().getTime() }));      

        dispatch({
            type: EVALUATED_RESUMES_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: EVALUATED_RESUMES_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const getEvaluatedResumeDetails = (pk, usersId) => async (dispatch) => {
    try {
        dispatch({ type: EVALUATED_RESUME_DETAIL_LOADING });

        if(usersId){
            const { data } = await axiosInstance.get(`/hr/evaluated-resume-details-byuser/${usersId}/`);
            dispatch({
                type: EVALUATED_RESUME_DETAIL_SUCCESS,
                payload: data,  
            });
            return data;
        }
        const { data } = await axiosInstance.get(`/hr/evaluated-resume-details/${pk}/`);
        dispatch({
            type: EVALUATED_RESUME_DETAIL_SUCCESS,
            payload: data,  
        });

        return data;
    } catch (error) {
        dispatch({
            type: EVALUATED_RESUME_DETAIL_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const createLiveQna = (qnaData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_LIVEQNA_LOADING });
        const { data } = await axiosInstance.post('/hr/create-live-qna/', qnaData);

        dispatch({
            type: CREATE_LIVEQNA_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: CREATE_LIVEQNA_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const getLiveQnaDetails = (pk) => async (dispatch) => {
    try {
        dispatch({ type: LIVEQNA_DETAILS_LOADING });

        const { data } = await axiosInstance.get(`/hr/live-interview-details/${pk}/`);
        dispatch({
            type: LIVEQNA_DETAILS_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: LIVEQNA_DETAILS_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
};

export const getLiveQnaList = () => async (dispatch) => {
    try {
        dispatch({ type: LIVEQNA_LIST_LOADING });

        const cachedData = localStorage.getItem('liveQnaList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: LIVEQNA_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('liveQnaList');
            }
        }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/hr/live-interview-qna/${userDetail.id}/`);
        localStorage.setItem('liveQnaList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: LIVEQNA_LIST_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: LIVEQNA_LIST_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const deleteLiveQna = (pk) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/hr/delete-live-interview/${pk}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};  

export const submitAnswers = (pk, answers) => async (dispatch) => {
    try {
        console.log('answers', answers);
        const { data } = await axiosInstance.post(`/hr/submit-answers/`, answers);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getResult = (pk) => async (dispatch) => {
    try {
        dispatch({ type: GET_RESULT_LOADING });

        const { data } = await axiosInstance.get(`/hr/get-result/${pk}/`);

        dispatch({
            type: GET_RESULT_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: GET_RESULT_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const downloadResult = (pk) => async (dispatch) => {
    try {
        const response = await axiosInstance.get(`/hr/resume/pdf/${pk}/`, { responseType: 'blob' });
        return response.data;
    } catch (error) {
        throw error;
    }
}