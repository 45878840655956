import React from 'react';
import { Link } from 'react-router-dom';

const CallToActionSection = () => {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-white py-16 ">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="relative rounded-2xl px-6 py-10 bg-gradient-to-r from-primary-50 to-primary-100 overflow-hidden shadow-md sm:px-12 sm:py-20">
          <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
            <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
              <path className="text-primary-200 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"></path>
              <path className="text-primary-300 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"></path>
            </svg>
          </div>
          <div className="relative flex flex-col items-center text-center">
            <h1 className="text-4xl font-extrabold text-gray-800 md:text-5xl">
              Transform Your Hiring Process
            </h1>
            <p className="mt-6 mx-auto max-w-2xl text-lg text-gray-700">
              Leverage our AI-powered tools to streamline your recruitment process. From resume evaluation to initial interviews, HR AI Box simplifies hiring for you.
            </p>
            <div className="flex flex-wrap justify-center gap-6 mt-10">
              <Link to="/signup" className="inline-flex items-center justify-center rounded-full bg-gradient-to-r from-primary-500 to-primary-700 py-3 px-8 text-base font-medium text-white hover:from-primary-700 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 transition-transform transform hover:scale-105">
                <span className="relative text-base font-semibold text-white">Get Started</span>
              </Link> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActionSection;
