import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createLiveQna, evaluateResume, getJobList } from '../../../redux/actions/jobActions';
import EvaluationResultModal from '../../../Dashboard/Page/ResumeEvaluate.js/EvaluationResultModal';
import Loading from '../../../Dashboard/Components/Loading';

const ResumeEvaluation = () => {
    const dispatch = useDispatch();
    const [resumeFile, setResumeFile] = useState(null);
    const [jobList, setJobList] = useState([]);
    const [selectedJob, setSelectedJob] = useState('');
    const [filePreview, setFilePreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isQnaLoading, setQnaIsLoading] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalMessage, setModalMessage] = useState('Evaluating Your Resume...');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [fileError, setFileError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const jobs = await dispatch(getJobList());
                setJobList(jobs);
            } catch (error) {
                console.error('Failed to fetch jobs', error);
            }
        };
        fetchJobs();
    }, [dispatch]);

    const handleJobChange = (e) => {
        setSelectedJob(e.target.value);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFileError('');
        if (file) {
            if (file.type !== 'application/pdf') {
                setFileError('Invalid file type. Only PDF is allowed.');
                setResumeFile(null);
                setFilePreview(null);
                e.target.value = null;
                return;
            }
            if (file.size > 2 * 1024 * 1024) {
                setFileError('File size must be less than 2MB.');
                setResumeFile(null);
                setFilePreview(null);
                e.target.value = null;
                return;
            }
            setResumeFile(file);
            setFilePreview({ name: file.name, url: URL.createObjectURL(file), type: file.type });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!resumeFile || !selectedJob) {
            setFileError('All fields are required');
            return;
        }
        setIsLoading(true);

        const formData = new FormData();
        formData.append('job', selectedJob);
        formData.append('resume_file', resumeFile);

        try {
            const fakeProgress = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        clearInterval(fakeProgress);
                        return 100;
                    }
                    return prevProgress + 1;
                });
            }, 100);

            const response = await dispatch(evaluateResume(formData));
            if (response) {
                setModalData(response);
                setIsModalOpen(true);
                localStorage.removeItem('userList');
                localStorage.removeItem('evaluated_resumes');
            } else {
                setFileError('Failed to evaluate resume');
            }
        } catch (error) {
            console.error('Error during evaluation:', error);
            setFileError('An error occurred during evaluation');
        } finally {
            setIsLoading(false);
        }
    };

    const handleNext = async () => {
        setQnaIsLoading(true);
        setIsModalOpen(false);

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const formData = {
            user: userDetail.id,
            id: null,
            job: selectedJob,
            level: 1,
            interview_type: 1,
            resume: modalData.id,
        };

        setProgress(0);
        setModalMessage('Creating Interview Questions...');

        try {
            const fakeProgress = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        clearInterval(fakeProgress);
                        return 100;
                    }
                    return prevProgress + 1;
                });
            }, 100);

            const res = await dispatch(createLiveQna(formData));
            if (res) {
                localStorage.removeItem('qnaList');
                navigate('/interview', { state: { id: res.id } });
            }
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setQnaIsLoading(false);
        }
    };

    const handleOnClose = () => {
        navigate('/candidate-dashboard');
    };

    return (
        <div className='min-h-screen bg-gray-50 py-8'>
            {(isLoading || isQnaLoading) && <Loading progress={progress} message={modalMessage} />}
            <div>
                <h1 className="text-3xl font-bold text-center text-gray-700 mb-2">Resume Evaluation</h1>
                <p className="text-gray-500 text-center mb-6">Here is the evaluation of your interview answers.</p>
            </div>
            <div className="flex justify-center items-center ">
                <main className="w-full max-w-3xl mx-4 md:mx-0 p-8 bg-white rounded-xl shadow-xl">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="mt-6">
                            <select
                                id="job"
                                value={selectedJob}
                                onChange={handleJobChange}
                                className="w-full px-4 py-3 font-base text-gray-500 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
                                required
                            >
                                <option className='font-base text-gray-500' value="">Select a job</option>
                                {jobList.map((job) => (
                                    <option className='font-base text-gray-500' key={job.id} value={job.id}>
                                        {job.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-6">
                            <label
                                htmlFor="resume"
                                className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white hover:bg-gray-50 transition-all duration-300">
                                <div className="flex flex-col items-center justify-center pt-5">
                                    <svg
                                        className="w-10 h-10 mb-4 text-primary-500"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 30 16"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                        />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">Click to upload your Resume</span> (PDF only)
                                    </p>
                                </div>
                                <input
                                    id="resume"
                                    type="file"
                                    accept=".pdf"
                                    className="hidden"
                                    onChange={handleFileChange}
                                    required
                                />
                            </label>
                            {fileError && <p className="text-red-500 text-xs mt-2">{fileError}</p>}
                            {resumeFile && (
                                <div className="mt-4">
                                    <a href={filePreview.url} target="_blank" rel="noopener noreferrer" className="text-primary-500 underline">
                                        {filePreview.name}
                                    </a>
                                </div>
                            )}
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                className="bg-primary-600 text-white py-2 px-12 rounded-full font-semibold shadow-md hover:bg-primary-700 transition duration-300"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Evaluating...' : 'Evaluate Resume'}
                            </button>
                        </div>
                    </form>
                </main>
                {isModalOpen && (
                    <EvaluationResultModal
                        isOpen={isModalOpen}
                        onClose={() => handleOnClose()}
                        data={modalData}
                        isQnaLoading={isQnaLoading}
                        submitNext={handleNext}
                        GoBack={() => navigate('/candidate-dashboard')}
                    />
                )}
            </div>
        </div>
    );
};

export default ResumeEvaluation;
