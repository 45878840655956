import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const ProtectedRoute = ({ element, requiredPermission }) => {
  const { isAuthenticated } = useAuth();
  const permissions = JSON.parse(localStorage.getItem('permissions')) || [];

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  if (requiredPermission && !permissions.includes(requiredPermission)) {
    return <Navigate to="/unauthorized" />; // A component or page to show unauthorized access
  }

  return element;
};

export default ProtectedRoute;
