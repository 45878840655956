import React from 'react';

const teamMembers = [
  {
    name: 'Jane Doe',
    role: 'AI Specialist',
    image: 'https://randomuser.me/api/portraits/women/44.jpg',
    description: 'Jane is an expert in AI and machine learning, leading our technology advancements.'
  },
  {
    name: 'John Smith',
    role: 'Project Manager',
    image: 'https://randomuser.me/api/portraits/men/46.jpg',
    description: 'John oversees project execution, ensuring everything runs smoothly and on time.'
  },
  {
    name: 'Emily Johnson',
    role: 'Data Scientist',
    image: 'https://randomuser.me/api/portraits/women/47.jpg',
    description: 'Emily analyzes and interprets complex data to provide actionable insights.'
  },
  {
    name: 'Michael Brown',
    role: 'Software Engineer',
    image: 'https://randomuser.me/api/portraits/men/48.jpg',
    description: 'Michael is responsible for developing and maintaining our software solutions.'
  },
  {
    name: 'Sarah Davis',
    role: 'UI/UX Designer',
    image: 'https://randomuser.me/api/portraits/women/49.jpg',
    description: 'Sarah designs intuitive user interfaces to enhance user experience.'
  },
  {
    name: 'David Wilson',
    role: 'Customer Success Manager',
    image: 'https://randomuser.me/api/portraits/men/50.jpg',
    description: 'David ensures our customers achieve their desired outcomes with our services.'
  }
];

const TeamSection = () => (
  <section className=''>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-10 lg:pb-24">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-1 text-sm tracking-wider text-primary-600 font-bold uppercase rounded-full bg-primary-50">
            Meet Our Team
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"> 
          <span className="relative">Get to Know</span>
          {' '}the experts behind our success
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Our team of dedicated professionals is committed to delivering exceptional results. Meet the people who make it all happen.
        </p>
      </div>
      <div className="grid gap-10 row-gap-8 mx-auto sm:row-gap-10 lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-3">
        {teamMembers.map((member, index) => (
          <div key={index} className="flex">
            <img className="object-cover w-20 h-20 mr-4 rounded-full shadow" src={member.image} alt={member.name} />
            <div className="flex flex-col justify-center">
              <p className="text-lg font-bold">{member.name}</p>
              <p className="text-sm text-gray-800">{member.role}</p>
              <p className="text-sm text-gray-600">{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default TeamSection;
