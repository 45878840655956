import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { createLiveQna, getInterviewType } from '../../../redux/actions/jobActions';

const CreateLiveQnaInterview = () => {
    const dispatch = useDispatch();
    const [jobType, setJobType] = useState([]);
    const [interviewType, setInterviewType] = useState([]);
    const [initData, setInitData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getInterviewType()).then((res) => {
            if (res) {
                setInterviewType(res.interview_types);
                setJobType(res.jobs);
            }   
        });
    }, [dispatch]);

    const handleInputChange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        setInitData({ ...initData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return; // Prevent multiple submissions
        setIsLoading(true);
        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const formData = {
            user: userDetail.id,
            id: null,
            job: initData.selectJob,
            level: 1,
            interview_type: initData.selectInterviewType,
        };
        setInitData({});

        try {
            const res = await dispatch(createLiveQna(formData)); 
            if (res) {
                localStorage.removeItem('qnaList');
                navigate('/live-interview', { state: { id: res.id }});
            }
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="justify-center items-center ">
            <main className="p-4">
                <div className="grid grid-col-1 mt-6 mb-6 ">
                    <h1 className="text-center text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Create Live QNA for Interview</h1>
                    <p className="text-center text-gray-500 dark:text-gray-300">
                        Add new job details!
                    </p>
                </div>
                <div className="min-h-screen p-6 flex flex-col items-center">
                    <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-8">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid gap-5 grid-cols-1 md:grid-cols-10">
                                <div className="my-4 md:col-span-5">
                                    <select
                                        id="selectJob"
                                        value={initData.selectJob || ''}
                                        onChange={handleInputChange}
                                        className={`w-full px-4 py-2 text-gray-500 font-normal border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400 ${errors.selectJob ? 'border-red-500' : 'border-gray-200'}`}
                                        required
                                    >
                                        <option value="">Select Job</option>
                                        {jobType && jobType.map((type) => (
                                            <option key={type.id} value={type.id}>
                                                {type.title}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.selectJob && <p className="text-red-500 text-xs mt-2">{errors.selectJob}</p>}
                                </div>
                                <div className="my-4 md:col-span-5">
                                    <select
                                        id="selectInterviewType"
                                        value={initData.selectInterviewType || ''}
                                        onChange={handleInputChange}
                                        className={`w-full px-4 py-2 text-gray-500 font-normal border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400 ${errors.selectInterviewType ? 'border-red-500' : 'border-gray-200'}`}
                                        required
                                    >
                                        <option value="">Select Interview Type</option>
                                        {interviewType && interviewType.map((type) => (
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.selectInterviewType && <p className="text-red-500 text-xs mt-2">{errors.selectInterviewType}</p>}
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    className="px-8 py-3 bg-gradient-to-r from-primary-500 to-primary-600 text-white font-semibold rounded-lg shadow-lg hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </form>
                    </div>
                    <ToastContainer />
                </div>
            </main>
        </div>
    );
};

export default CreateLiveQnaInterview;
