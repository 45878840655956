import React from 'react';

const testimonials = [
  {
    name: "John Smith",
    role: "HR Manager at TechCorp",
    image: "https://randomuser.me/api/portraits/men/45.jpg",
    text: "HR AI Box has transformed our hiring process. The AI-powered resume screening and interview evaluation have saved us countless hours and improved the quality of our hires."
  },
  {
    name: "Jane Doe",
    role: "Recruiter at InnovateX",
    image: "https://randomuser.me/api/portraits/women/65.jpg",
    text: "The automated initial interviews conducted by HR AI Box are incredibly accurate and efficient. We can now focus on candidates who are truly a good fit for our company."
  },
  {
    name: "Michael Brown",
    role: "Talent Acquisition at CodeWorks",
    image: "https://randomuser.me/api/portraits/men/33.jpg",
    text: "HR AI Box has significantly reduced the time it takes to shortlist candidates. The AI's ability to evaluate resumes and interviews is remarkable."
  },
  {
    name: "Emily Davis",
    role: "HR Specialist at Creative Solutions",
    image: "https://randomuser.me/api/portraits/women/68.jpg",
    text: "Using HR AI Box, we've been able to streamline our recruitment process. The insights provided by the AI have been invaluable in making informed hiring decisions."
  },
  {
    name: "David Lee",
    role: "Operations Manager at LogisticsPro",
    image: "https://randomuser.me/api/portraits/men/78.jpg",
    text: "The reliability and accuracy of HR AI Box's services have been exceptional. We trust the AI to handle our initial interview stages, ensuring we only interview the best candidates."
  }
];

const TestimonialsSection = () => {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-white py-16 md:pb-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900">
            Trusted by Leading Companies
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            See how HR AI Box has transformed the hiring process for companies like yours.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {testimonials.map((testimonial, index) => (
            <figure key={index} className="relative rounded-2xl bg-white p-6 shadow-md shadow-slate-900/10">
              <svg aria-hidden="true" width="105" height="78" className="absolute top-6 left-6 fill-primary-50">
                <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
              </svg>
              <blockquote className="relative">
                <p className="text-md text-gray-500">
                  {testimonial.text}
                </p>
              </blockquote>
              <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                <div>
                  <div className="font-display text-base text-slate-900">{testimonial.name}</div>
                  <div className="mt-1 text-sm text-slate-500">{testimonial.role}</div>
                </div>
                <div className="overflow-hidden rounded-full bg-slate-50">
                  <img alt={testimonial.name} src={testimonial.image} className="h-14 w-14 object-cover" loading="lazy" width="56" height="56" />
                </div>
              </figcaption>
            </figure>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
