import React from 'react';
import { Link } from 'react-router-dom';
const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="flex flex-col space-y-10 justify-center p-10 bg-gray-50">
      <nav className="flex justify-center flex-wrap gap-6 text-gray-400 font-medium">
        <Link className="hover:text-gray-900" to="/">Home</Link>
        <Link className="hover:text-gray-900" to="/jobs">Jobs</Link>
        <Link className="hover:text-gray-900" to="/contact">Contact</Link>
      </nav>

      <div className="flex justify-center space-x-5">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/facebook-new.png" alt="Facebook" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/linkedin-2.png" alt="LinkedIn" />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/instagram-new.png" alt="Instagram" />
        </a>
        <a href="https://messenger.com" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/facebook-messenger--v2.png" alt="Messenger" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/fluent/30/000000/twitter.png" alt="Twitter" />
        </a>
      </div>

      <p className="text-center text-gray-500 ">&copy; {currentYear} HR.AI Box. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
