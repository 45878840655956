import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import { MdWork } from 'react-icons/md';
import { Link } from 'react-router-dom';

const truncateDescription = (description, wordLimit) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = description;
  const text = tempDiv.textContent || tempDiv.innerText || '';
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

const JobCard = ({ job, handleJobDetails }) => {
  return (
    <div className="rounded-lg border border-gray-300 bg-white p-6 shadow-lg relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-primary-50 to-white opacity-50 z-0"></div>
      <div className="relative z-10 flex items-center justify-between mb-4">
        <div className="bg-primary-500 p-2 rounded-full">
          <MdWork className="text-white text-2xl" />
        </div>
        <div className="text-right">
          <h4 className="text-lg font-medium text-gray-700">{job.title}</h4>
          <h3 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary-500 to-green-500">{job.company}</h3>
        </div>
      </div>
      <div className="space-y-3 relative z-10">
        <p className="text-gray-600">{truncateDescription(job.description, 20)}</p>
        <div className="flex items-center text-gray-600">
          <FaCalendarAlt className="mr-2" />
          <span>{new Date(job.created_at).toLocaleDateString()}</span>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 w-24 h-24 bg-gradient-to-br from-primary-300 to-primary-500 opacity-30 rounded-full transform translate-x-1/2 translate-y-1/2 z-0"></div>
      <div className="relative z-10 text-center mt-4">
        <button
          onClick={() => handleJobDetails(job.id)}
          className="bg-primary-600 text-white py-2 px-4 rounded-full hover:bg-primary-700 transition duration-300"
        >
          View Details
        </button>
      </div>
    </div>
  );
};

export default JobCard;
