import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaFileInvoice, FaSearch, FaRegTrashAlt } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { getJobList, deleteJob } from '../../../redux/actions/jobActions';

const JobList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();  

  const itemsPerPage = 10; 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(getJobList());
        setJobs(data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); 
  }, []);

  const handleEdit = (jobId) => {
    navigate('/edit-job', { state: {id: jobId} });
  } 

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  }; 

  const handleDelete = (jobId) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this job? It will delete all related jobs.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await dispatch(deleteJob(jobId));
              setJobs(jobs.filter(job => job.id !== jobId));
              localStorage.removeItem('jobTypesList');
              localStorage.removeItem('jobList');
              toast.success('Job deleted successfully');
            } catch (error) {
              toast.error('Error deleting job');
              console.error('Delete error:', error);
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const filteredJobs = Array.isArray(jobs) ? jobs.filter((job) =>
    (job.title && job.title.toLowerCase().includes(filter.toLowerCase())) ||
    (job.created_at && job.created_at.includes(filter))
  ) : [];

  const offset = currentPage * itemsPerPage;
  const currentJobs = filteredJobs.slice(offset, offset + itemsPerPage);

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <div className="relative w-1/3">
          <FaSearch className="absolute top-2.5 left-3 text-gray-500" />
          <input
            type="text"
            placeholder="Filter jobs"
            className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:ring focus:ring-primary-200 focus:border-primary-500"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <Link to="/add-job" className="flex items-center px-2 md:px-8 py-2 text-white bg-primary-600 rounded-md shadow-md hover:from-primary-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
          <FaPlus className="mr-2" />
          Add Job
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead className="bg-gradient-to-r from-primary-100 to-indigo-100">
            <tr>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Job</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Date</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Action</th>
            </tr>
          </thead>
          <tbody>
          {currentJobs.length > 0 ? (
            currentJobs.map((job, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="py-3 px-5 text-sm text-gray-800">{job.title}</td>
                <td className="py-3 px-5 text-sm text-gray-800">{formatDate(job.created_at)}</td>
                <td className="py-3 px-5 text-sm">
                  <button onClick={() => handleEdit(job.id)} className="px-4 py-1 mb-1 md:mb-0 text-primary-600 border border-primary-600 bg-white hover:bg-primary-600 hover:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Edit</button>
                  <button
                      onClick={() => handleDelete(job.id)}
                      className="px-4 ml-2 py-2 text-red-500 border border-red-500 bg-white hover:bg-red-500 hover:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                      <FaRegTrashAlt size={12} />
                  </button>
                </td>
              </tr>
            ))) : (
                <tr>
                  <td colSpan="3" className="py-10 text-center">
                    <div className="flex flex-col items-center">
                      <FaFileInvoice className="mb-3 text-6xl text-gray-400" />
                      <p className="text-lg font-medium text-gray-600">No jobs Found</p>
                      <p className="text-sm text-gray-500">Try adding new job!</p>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <div className="mt-6">
      {currentJobs.length > 0 && (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={Math.ceil(filteredJobs.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'flex justify-center space-x-2'}
          pageClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
          previousClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
          nextClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
          breakClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
          activeClassName={'bg-primary-600 text-white'}
        />)}
      </div>
    </div>
  );
};

export default JobList;
