import React from 'react';

const Header = () => (
    <div className="text-center mb-12">
        <h1 className="text-2xl md:text-4xl font-bold text-gray-800">Interview Q&A Result</h1>
        <p className="text-gray-500">Here is the evaluation of your interview answers.</p>
    </div>
);

export default Header;
