import React, { useEffect, useState } from 'react';
import JobCard from './JobCard';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getJobList } from '../../../redux/actions/jobActions';
import ReactPaginate from 'react-paginate';
import JobHero from './JobHero';

const Jobs = () => {
    const [jobs, setJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const data = await dispatch(getJobList());
                setJobs(data);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, [dispatch]);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const offset = currentPage * itemsPerPage;
    const currentJobs = jobs ? jobs.slice(offset, offset + itemsPerPage) : [];

    const handleJobDetails = (jobId) => {   
        navigate(`/job-details`, { state: { jobId } });
    }

    return (
        <div className='bg-gray-50'>
            <JobHero />
            <div className="container mx-auto px-12  py-24">

                {loading ? (
                    <div className="text-center text-xl">Loading...</div>
                ) : (
                    <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            {currentJobs.map((job) => (
                                <JobCard key={job.id} job={job} handleJobDetails={handleJobDetails} />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>

    );
};

export default Jobs;
