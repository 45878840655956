import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../../redux/env';

const EmailVerified = () => {
  const [emailVerified, setEmailVerified] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      axios.get(`${API_URL}/auth/verify-email/?token=${token}`)
        .then(response => {
          setEmailVerified('success');
        })
        .catch(error => {
          setEmailVerified('failed');
        });
    } else {
      navigate('/signin');
    }
  }, [token, navigate]);

  const handleSignIn = () => {
    navigate('/signin');
  };

  return (
    <div className="flex items-center justify-center flex-col min-h-screen bg-gray-50">
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-8">
        <div className="flex items-center justify-center flex-col gap-5">
          {!emailVerified && (
            <>
              <div role="status">
                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
              <h2 className="text-xl font-semibold text-gray-700 text-center">Verifying your email...</h2>
              <p className="text-center text-gray-600">Please wait while we verify your email address. This might take a few moments.</p>
            </>
          )}

          {emailVerified === 'success' && (
            <>
              <div className="text-primary-600 w-10 h-10 mx-auto">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" className="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-277.341 82.745l184-184c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.971 0L216 285.228l-95.372-95.373c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l144 144c4.686 4.686 12.284 4.686 16.971-.001z"></path></svg>
              </div>
              <h2 className="text-xl font-semibold mb-4">Email Verified Successfully!</h2>
              <button onClick={handleSignIn} className="flex px-8 py-2 bg-primary-600 text-white rounded-full font-semibold hover:bg-primary-700">
                Sign In Now
              </button>
            </>
          )}

          {emailVerified === 'failed' && (
            <>
              <div className="text-red-500 w-16 h-16 mx-auto animate-bounce">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm91.803 333.803c4.686 4.686 4.686 12.284 0 16.971l-28.284 28.284c-4.686 4.686-12.284 4.686-16.971 0L256 300.971l-46.548 46.548c-4.686 4.686-12.284 4.686-16.971 0l-28.284-28.284c-4.686-4.686-4.686-12.284 0-16.971L211.029 256l-46.548-46.548c-4.686-4.686-4.686-12.284 0-16.971l28.284-28.284c4.686-4.686 12.284-4.686 16.971 0L256 211.029l46.548-46.548c4.686-4.686 12.284-4.686 16.971 0l28.284 28.284c4.686 4.686 4.686 12.284 0 16.971L300.971 256l46.548 46.548z"></path></svg>
              </div>
              <h2 className="text-xl font-semibold mb-4">Email Verification Failed!</h2>
              <button onClick={handleSignIn} className="px-8 py-2 bg-primary-500 text-white rounded-full font-semibold hover:bg-primary-600">
                Sign In
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
