import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Userlogin, googleAuth, resendVerifyEmail } from '../../../redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useAuth } from '../../../Common/AuthProvider';

const SigninPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const { loading, error } = authState;
    const { login, isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1);
        }
    }, [isAuthenticated]);

    const validate = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        } else if (email.length > 100) {
            errors.email = 'Email cannot exceed 100 characters.';
        }
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters.';
        } else if (password.length > 50) {
            errors.password = 'Password cannot exceed 50 characters.';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            const userData = {
                email,
                password,
            };
            try {
                const res = await dispatch(Userlogin(userData));
                if (res && res.access) {
                    if (!res.user.is_verified) {
                        localStorage.removeItem('userList');
                        navigate('/email-verification');
                    } else {
                        login(res);
                    }
                }
            } catch (error) {
                console.error("Login error:", error);
            }
        }
    };

    const handleResendVerification = () => {
        dispatch(resendVerifyEmail(email)).then((res) => {
            if (res) {
                navigate('/email-verification', { state: { email: email } });
            }
        });
    };

    const handleGoogleSuccess = async (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const userData = {
            email: decoded.email,
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            id_token: token,
            role: 4
        };
        await dispatch(googleAuth(userData)).then((res) => {
            if (res && res.access) {
                localStorage.removeItem('userList');
                login(res);
            }
        });
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const renderError = (err) => (
        <div>
          {err}
          {err.includes('Email is not verified') && (
            <button onClick={handleResendVerification} className="ml-2 underline">
              Resend Verification Email
            </button>
          )}
        </div>
    );

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-primary-50 to-gray-50 py-12">
            <div className="max-w-md w-full mx-4 md:mx-0 bg-white p-8 rounded-xl shadow-lg">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold text-gray-900">
                        Log in to HR.AI Box
                    </h2>
                    <p className="text-gray-600">
                        Or <Link to="/signup" className="font-medium text-primary-500 hover:text-primary-600">create a new account</Link>
                    </p>
                </div>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.email ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                        />
                        {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.password ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                        />
                        {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                    </div>

                    <div className="md:flex items-center justify-between">
                        <div className="flex mb-2 md:mb-0 items-center">
                            <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                className="h-4 w-4 text-primary-500 focus:ring-primary-500 border-gray-300 rounded"
                            />
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                Remember me
                            </label>
                        </div>

                        <div className="text-sm">
                            <Link to="/reset-password" className="font-medium text-primary-500 hover:text-primary-600">
                                Forgot your password?
                            </Link>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-3 px-4 border border-transparent font-medium rounded-full text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                            <svg
                                className="w-6 h-6 mr-2"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                                <circle cx="8.5" cy="7" r="4" />
                            </svg>
                            Log in
                        </button>
                    </div>
                </form>
                <div className="text-center mt-6">
                    <p className="font-bold text-gray-500">OR</p>
                </div>
                <div className="w-full flex-1 mt-6">
                    <div className="flex flex-col items-center">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onFailure={handleGoogleFailure}
                        />
                    </div>
                </div>
                {loading && <p className="mt-4 text-gray-600">Loading...</p>}
                {error && (
                    <div className="text-red-500 mt-2 text-sm">
                    {Array.isArray(error) ? error.map((err, index) => (
                        <div key={index}>
                        {renderError(err)}
                        </div>
                    )) : renderError(error)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SigninPage;
