import React from 'react';
import Chart from 'react-apexcharts';

const SalesGraphCard = ({selectedCandidatesData}) => {
  const series = [{
    name: 'Selected Candidates',
    data: selectedCandidatesData
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '20%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: selectedCandidatesData.map((_, idx) => `Day ${idx + 1}`), // Generate days dynamically
      labels: {
        style: {
          colors: '#a0aec0',
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Number of Candidates',
        style: {
          color: '#a0aec0',
          fontSize: '12px'
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    },
    grid: {
      borderColor: '#e2e8f0'
    },
    colors: ['#22c55e']
  };

  return (
    <div className="rounded-lg border border-stroke bg-white p-6 shadow-md dark:border-strokedark dark:bg-boxdark">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Selected Candidates Overview</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Last Month</p>
        </div>
        <div className="text-right">
          <p className="text-2xl font-bold text-gray-800 dark:text-white">{`${selectedCandidatesData.reduce((acc, val) => acc + Number(val), 0)}`}</p>
          <p className="text-sm text-red-500 flex items-center">
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24">
              <path d="M17 13l-5 5m0 0l-5-5m5 5V6"></path>
            </svg>
            -1.5%
          </p>
        </div>
      </div>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default SalesGraphCard;
