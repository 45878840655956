import React from 'react';

const JobHero = () => {
  return (
    <>
      <section className="py-14 lg:py-24 text-center bg-gradient-to-r from-primary-100 to-green-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-5 md:text-5xl">
            Discover Your Dream Job with <span className="text-primary-600">Our Job Portal</span>
          </h1>
          <p className="text-base text-gray-500 mb-9 max-w-md mx-auto">
            Explore thousands of job listings, find the perfect match for your skills and interests, and take the next step in your career.
          </p> 
        </div>
      </section>
    </>
  );
};

export default JobHero;