import React, { useState } from 'react';
import JobList from './Jobs/JobList';

const JobView = () => {

    return (
        <div className="justify-center items-center ">
            <main className="p-4">
                <div className="grid grid-col-1 mt-6 mb-6 ">
                    <h1 className="text-2xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Job List</h1>
                    <p className="text-gray-500 dark:text-gray-300">
                    List of created jobs!
                    </p>
                </div>
                <JobList /> 
            </main>
        </div>
    );
};

export default JobView;
