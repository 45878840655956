import React from 'react';
import { FaUserTie, FaHandshake, FaLaptopCode } from 'react-icons/fa';

const services = [
  {
    icon: <FaUserTie className="text-primary-500" size={30} />,
    title: "Talent Acquisition",
    description: "Find the best talent with our AI-driven talent acquisition solutions. Ensure you get the right fit for your organization every time."
  },
  {
    icon: <FaHandshake className="text-red-500" size={25} />,
    title: "Seamless Onboarding",
    description: "Streamline the onboarding process for new hires with our efficient and user-friendly platform. Get new team members up to speed in no time."
  },
  {
    icon: <FaLaptopCode className="text-green-500" size={25} />,
    title: "Skills Development",
    description: "Provide continuous learning opportunities for your employees with our comprehensive skills development tools and resources."
  }
];

const HomePageLayout = () => {
  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-16 md:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-5xl font-extrabold text-gray-900">
            Elevate Your HR Processes with <span className='text-primary-600'>HR.AI</span> Box
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Discover innovative solutions that can revolutionize your HR management and elevate your business to new heights.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {services.map((service, index) => (
            <div key={index} className="p-8 rounded-2xl bg-gradient-to-b from-primary-50 to-gray-50 shadow-lg">
              <div className="flex items-center justify-center mb-6">
                {service.icon}
              </div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-3 text-center">
                {service.title}
              </h3>
              <p className="text-gray-700 text-center">
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePageLayout;
