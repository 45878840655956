import axios from 'axios';
import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,

    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,   

    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,

    CONTACT_MESSAGE_REQUEST,
    CONTACT_MESSAGE_SUCCESS,
    CONTACT_MESSAGE_FAIL,

    USERS_REQUEST,
    USERS_SUCCESS,
    USERS_FAIL,
} from '../constants/authConstants';

import { API_URL } from '../env';
import { startLoading, stopLoading } from './loadingActions';
import axiosInstance from '../../Common/axiosInstance';

export const register = (data) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: REGISTER_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.post(`${API_URL}/auth/register/`, data, config);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: response.data,
        });
        return response.data;
    } catch (error) {
        dispatch({
            type: REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    } finally {
        dispatch(stopLoading());
    }
};

export const Userlogin = (data) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: LOGIN_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const { data: res } = await axios.post(`${API_URL}/auth/login/`, data, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res,
        });

        localStorage.setItem('role', res.user.role);
        localStorage.setItem('permissions', JSON.stringify(res.user.permissions));

        return res; 
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;  // Throw the error to handle it in the component
    } finally {
        dispatch(stopLoading());
    }
};

export const getUsers = () => async (dispatch) => {
    try {
        dispatch({ type: USERS_REQUEST });

        const cachedData = localStorage.getItem('userList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: USERS_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('userList');
            }
        }
        
        const { data } = await axiosInstance.get('/auth/users/');
        localStorage.setItem('userList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: USERS_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: USERS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const googleAuth = (userData) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: LOGIN_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const { data: res } = await axios.post(`${API_URL}/auth/google-login/`, userData, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res,
        });

        localStorage.setItem('role', res.user.role);
        localStorage.setItem('permissions', JSON.stringify(res.user.permissions));

        return res;
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    } finally {
        dispatch(stopLoading());
    }
}; 

export const resendVerifyEmail = (email) => async (dispatch) => {
    try {
        dispatch({ type: VERIFY_EMAIL_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const { data: res } = await axios.post(`${API_URL}/auth/resend-verification/`, { email }, config);
        dispatch({
            type: VERIFY_EMAIL_SUCCESS,
            payload: res,
        });
        return res;
    } catch (error) {
        dispatch({
            type: VERIFY_EMAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const resetPassword = (data) => async (dispatch) => {
    try {
        dispatch({ type: RESET_PASSWORD_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const { data: res } = await axios.post(`${API_URL}/auth/password-reset-email/`, data, config);
        dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: res,
        });
        return res;
    } catch (error) {
        dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}       

export const updatePassword = (data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PASSWORD_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const { data: res } = await axios.post(`${API_URL}/auth/password-reset/`, data, config);
        dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
            payload: res,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}   

export const logout = () => (dispatch) => {
    dispatch({ type: LOGOUT });
};

export const updateProfile = (userData) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: UPDATE_PROFILE_REQUEST }); 
        
        const { data } = await axiosInstance.post('/auth/update-profile/', userData);

        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: data,
        });
        return data;

    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    } finally {
        dispatch(stopLoading());
    } 
}

export const userProfile = () => async (dispatch) => {
    try {
        dispatch({ type: USER_PROFILE_REQUEST });
        const { data } = await axiosInstance.get('/auth/user-profile/');
        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: USER_PROFILE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const contactMessage = (data) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: CONTACT_MESSAGE_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.post(`${API_URL}/auth/contact/`, data, config);
        dispatch({
            type: CONTACT_MESSAGE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: CONTACT_MESSAGE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    } finally {
        dispatch(stopLoading());
    }
};