// src/components/Editor.js

import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "../../style/editor.css";

class NonStrictWrapper extends React.Component {
    render() {
      return this.props.children;
    }
}

const CommonEditor = ({ value, onChange }) => {
    return (
        <NonStrictWrapper>
            <ReactQuill
                value={value}
                onChange={onChange}
                modules={CommonEditor.modules}
                formats={CommonEditor.formats}
                theme="snow"
                // className='h-80'
            />
        </NonStrictWrapper>
    );
};

CommonEditor.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
         {'indent': '-1'}, {'indent': '+1'}],
        ['link'], 
        ['clean'],
        [{ 'align': [] }],
        ['code-block']
    ],
};

CommonEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
    'align', 'code-block'
];

export default CommonEditor;
