import React from "react";
import { AiOutlineStop } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => { 

    const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen bg-gray-50">
      <section className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg">
        <div className="h-[300px] bg-[#D9534F] px-12 mb-12 w-full text-white flex items-center justify-center flex-col gap-5 rounded-t-lg">
          <div className="flex items-center gap-3">
            <div className="w-10 h-[1px] bg-white"></div>
            <AiOutlineStop size={30} />
            <div className="w-10 h-[1px] bg-white"></div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-center text-sm sm:text-xl tracking-widest font-normal">
              ACCESS DENIED
            </div>
            <div className="text-center text-xl sm:text-3xl tracking-wider font-bold capitalize">
              Unauthorized Access
            </div>
            <p className="text-center">
              You do not have permission to view this page.
            </p>
            <button onClick={() => navigate('/')} className="bg-white my-4 text-[#D9534F] mx-10 px-5 py-2 rounded-lg font-semibold tracking-wider hover:bg-gray-200 transition duration-200">
              Go Back
            </button>
          </div>
        </div>
        <div className="px-12 pb-12 text-gray-800">
          <p className="text-center text-lg">
            Please contact your administrator if you believe this is a mistake. Ensure you have the necessary permissions to access this page.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Unauthorized;

const LockIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path d="M12 17c1.66 0 2.99-1.34 2.99-3L15 9c0-2.21-1.79-4-4-4S7 6.79 7 9v5c0 1.66 1.34 3 3 3zm6-3v-5c0-3.31-2.69-6-6-6S6 5.69 6 9v5c0 2.76 2.24 5 5 5s5-2.24 5-5z"></path>
    </svg>
  );
};
