import React from 'react';

const HeroSection = () => {
  return (
    <>
    <section className="py-14 lg:py-24 text-center bg-gradient-to-r from-fuchsia-100 to-cyan-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-5 md:text-5xl">
          Streamline Your Invoicing with Our <span className="text-primary-600">Invoice Extractor</span>
        </h1>
        <p className="text-base text-gray-500 mb-9 max-w-md mx-auto">
          Automate and simplify your invoicing process with our intelligent invoice extraction tool.
        </p>
      </div>
    </section>
    </>
  );
};

export default HeroSection;
