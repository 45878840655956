import React from 'react';
import { FaThumbsUp, FaRegLightbulb, FaAward, FaHeadset } from 'react-icons/fa';

export const AboutUsSection = () => {
  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:px-16">
      <div className="max-w-7xl mx-auto">
      <h2 className="inline-block px-3 py-1 text-sm tracking-wider text-primary-600 font-bold uppercase rounded-full bg-primary-50">About InvoAI</h2>
      <div className="flex flex-wrap md:flex-nowrap text-left">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl mr-12">Powerful AI for Efficient Invoice Processing</h2>
          <p className="mt-4 text-lg text-gray-500 mx-auto max-w-2xl">
            We strive to develop innovative AI solutions that are ideal for businesses of all sizes. Using Generative AI model simplifies and automates the invoice extraction process, making it efficient and accurate.
          </p>
        </div>
        <div className="mt-20">
          <div className="flex justify-center">
            <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
              <div className="text-center p-6 rounded-2xl shadow-md">
                <FaThumbsUp className="mx-auto h-8 w-8 text-primary-600" />
                <h3 className="mt-6 text-xl font-semibold text-gray-900">Trusted Technology</h3>
                <p className="mt-2 text-base text-gray-500">We deliver top-notch AI solutions for seamless invoice processing.</p>
              </div>
              <div className="text-center p-6 rounded-2xl shadow-md">
                <FaRegLightbulb className="mx-auto h-8 w-8 text-primary-600" />
                <h3 className="mt-6 text-xl font-semibold text-gray-900">Innovative Solutions</h3>
                <p className="mt-2 text-base text-gray-500">Generative AI model adapts to your business needs, providing customized results.</p>
              </div>
              <div className="text-center p-6 rounded-2xl shadow-md">
                <FaAward className="mx-auto h-8 w-8 text-primary-600" />
                <h3 className="mt-6 text-xl font-semibold text-gray-900">Award Winning</h3>
                <p className="mt-2 text-base text-gray-500">Recognized for our excellence in AI technology and customer service.</p>
              </div>
              <div className="text-center p-6 rounded-2xl shadow-md">
                <FaHeadset className="mx-auto h-8 w-8 text-primary-600" />
                <h3 className="mt-6 text-xl font-semibold text-gray-900">24/7 Support</h3>
                <p className="mt-2 text-base text-gray-500">We offer round-the-clock support to ensure your business runs smoothly.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 