import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const InterviewSelection = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state;

    const handleSelection = (mode) => {
        if (mode === 'text') {
            navigate('/text-interview', { state: { id: id } });
        } else if (mode === 'audio') {
            navigate('/audio-interview', { state: { id: id } });
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 py-8">
            <main className="w-full max-w-3xl p-4 bg-white rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold text-gray-800 text-center mb-6">Select Interview Mode</h1>
                <div className="flex justify-around">
                    <button
                        onClick={() => handleSelection('text')}
                        className="px-4 py-2 bg-primary-500 text-white font-semibold rounded-lg shadow-lg hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                    >
                        Text Interview
                    </button>
                    <button
                        onClick={() => handleSelection('audio')}
                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-300"
                    >
                        Audio Interview
                    </button>
                </div>
            </main>
        </div>
    );
};

export default InterviewSelection;
