import React from 'react';
import { FaVolumeUp } from 'react-icons/fa';

const QuestionDisplay = ({ question, handleSpeak, questionNumber }) => (
    <div>
        <h3 className="text-lg font-semibold text-gray-800 flex items-center justify-between">
            <span>Question {questionNumber}:</span>
            <button
                onClick={handleSpeak}
                className="ml-2 px-3 py-2 bg-primary-500 text-white rounded-full hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                title="Listen to Question"
            >
                <FaVolumeUp />
            </button>
        </h3>
        <p className="text-gray-700">{question}</p>
    </div>
);

export default QuestionDisplay;
